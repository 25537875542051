import React, { lazy,Suspense } from 'react';
import { BrowserRouter } from "react-router-dom";
import { Provider } from 'react-redux'
import configureStore from './store';
import './utils/helpers';
// import "./utils/BrowserPrint-3.0.216.min.js";
import * as dotenv from 'dotenv' 
import { AxiosInterceptor } from './utils/axiosClient'
import QueuebuserPage from './components/common/SuspenseLoader';
const DesktopApp =  lazy(() => import("./AppHelper"));
const MobileApp = lazy(() => import("./mobile/common/LandingPage"));
dotenv.config()

const {  store } = configureStore();
const App = () => {
  return(
    <Provider store={store}>
    <BrowserRouter>
       <AxiosInterceptor>
        <Suspense fallback={<QueuebuserPage/>}>
           {window.screen.width < 450 ? <MobileApp /> : <DesktopApp/>}
        </Suspense>
      </AxiosInterceptor>
      </BrowserRouter>
    </Provider>
  )
}

export default App;

