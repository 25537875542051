import {FETCH_REGIONS_SUCCESS, FETCH_STORE_SALES_SUCCESS, FETCH_STORE_PRODUCT_SALES_SUCCESS, FETCH_REGION_SALES_SUCCESS, FETCH_PRODUCT_SALES_SUCCESS, FETCH_CATEGORY_SALES_SUCCESS, FETCH_BRAND_SALES_SUCCESS, FETCH_IH_SALES_SUCCESS, FETCH_STAFF_SALES_SUCCESS, FETCH_DEVICE_SALES_SUCCESS, FETCH_CUSTOMER_SALES_SUCCESS ,
    FETCH_PRODUCT_TRANSACTION_TRACKER_SUCCESS,
    FETCH_PRODUCT_TRANSACTION_TRACKER_REQUEST,
    FETCH_PRODUCT_TRANSACTION_TRACKER_FAILURE,
    FETCH_BUNDLE_SALES_SUCCESS,FETCH_ENTITY_SALES_SUCCESS} from '../actions/types';


    const getTransactionData ={
        data:[],
        isLoading:null,
        attributes: []
    }

export default {
    regions: function(state = null, action) {
        switch(action.type) {
            case FETCH_REGIONS_SUCCESS:
                return action.payload.data;
            default:
                return state;
        }
    },

    storeSales: function(state = null, action) {
        switch(action.type) {
            case FETCH_STORE_SALES_SUCCESS:
                return action.payload;
            default:
                return state;
        }
    },

    storeProductSales: function(state = null, action) {
        switch(action.type) {
            case FETCH_STORE_PRODUCT_SALES_SUCCESS:
                return action.payload.data;
            default:
                return state;
        }
    },

    regionSales: function(state = null, action) {
        switch(action.type) {
            case FETCH_REGION_SALES_SUCCESS:
                return action.payload.data;
            default:
                return state;
        }
    },

    entitySales: function(state = null, action) {
        switch(action.type) {
            case FETCH_ENTITY_SALES_SUCCESS:
                return action.payload.data;
            default:
                return state;
        }
    },

    productSales: function(state = null, action) {
        switch(action.type) {
            case FETCH_PRODUCT_SALES_SUCCESS:
                return action.payload.data;
            default:
                return state;
        }
    },

    categorySales: function(state = null, action) {
        switch(action.type) {
            case FETCH_CATEGORY_SALES_SUCCESS:
                return action.payload.data;
            default:
                return state;
        }
    },

    bundleSales: function(state = null, action) {
        switch(action.type) {
            case FETCH_BUNDLE_SALES_SUCCESS:
                return action.payload.data;
            default:
                return state;
        }
    },


    brandSales: function(state = null, action) {
        switch(action.type) {
            case FETCH_BRAND_SALES_SUCCESS:
                return action.payload.data;
            default:
                return state;
        }
    },

    IHSales: function(state = null, action) {
        switch(action.type) {
            case FETCH_IH_SALES_SUCCESS:
                return action.payload.data;
            default:
                return state;
        }
    },

    staffSales: function(state = null, action) {
        switch(action.type) {
            case FETCH_STAFF_SALES_SUCCESS:
                return action.payload.data.data;
            default:
                return state;
        }
    },

    deviceSales: function(state = null, action) {
        switch(action.type) {
            case FETCH_DEVICE_SALES_SUCCESS:
                return action.payload.data.data;
            default:
                return state;
        }
    },

    customerSales: function(state = null, action) {
        switch(action.type) {
            case FETCH_CUSTOMER_SALES_SUCCESS:
                return action.payload.data.data;
            default:
                return state;
        }
    },
}

export const ProdTransactionTracker= (state = getTransactionData, { type, payload }) => {
    switch (type){
        case FETCH_PRODUCT_TRANSACTION_TRACKER_REQUEST:
            state.isLoading = true;
            return {
                ...state
            }
            case FETCH_PRODUCT_TRANSACTION_TRACKER_SUCCESS:
                state.isLoading = false;
                state.data=payload.data;
                state.attributes = payload.attributes;
            
            return {
                ...state
            }
            case FETCH_PRODUCT_TRANSACTION_TRACKER_FAILURE:
                state.isLoading = false;
                return {
                    ...state
                }
        default:
            return state;
    }
}