        // SETTING BRANDCONFIG CONFIGURATION TO USE IN SCSS VARIABLES // whitelabel.scss
        document.documentElement.style.setProperty('--pagaPrimaryColor', `#2B3B45`);
        document.documentElement.style.setProperty('--pagaSecondaryColor', `#f6f6f7`);
                // ENDS
        export default {
            "brandName" : "PAGA",
            "brandLogo" : "https://quebuster.s3.ap-south-1.amazonaws.com/website/assets/Group+163374.webp",
            "brandPrimaryColor":'#2B3B45',
            "brandSecondaryColor":"#f6f6f7",
            "brandLightRedSecondaryColor":"#FFCCCB",
            "brandHeaderColor":'#131D22',
            "loginScreen": {
                "brandLogo": "https://quebuster.s3.ap-south-1.amazonaws.com/website/assets/login-form-logo.webp",
                "headerLogo": "https://quebuster.s3.ap-south-1.amazonaws.com/website/assets/Doroki+logo-01+6.webp",
                "headerColor": "#2B3B45",
                "primaryColor": "#2B3B45",
                "secondaryColor": "#fff",
                "logoWidth": "140px",
                "headerLogoWidth": "80px",
                "logoHeight": "45px"
            },
            "sidebarIcons":{
              "home":require("../icons/homePaga.svg"),
              "catalog":require("../icons/catalogPaga.svg"),
              "inventory":require("../icons/catalogPaga.svg"),
              "purchase":require("../icons/purchasePaga.svg"),
              "customer":require("../icons/customerPaga.svg"),
              "reports":require("../icons/reportsPaga.svg"),
              "settings":require("../icons/settingsPaga.svg"),
              "estore":require("../icons/estorePaga.svg"),
              "khata":require("../icons/Khata.svg"),
              "developer":require("../icons/devhooksPaga.svg"),
              "integrations":require("../icons/integrationPaga.svg"),
              "billing":require("../icons/billingPaga.svg"),
              "appointments":require("../icons/appointmentPaga.svg"),
              "urbanpiper":require("../icons/urbanpiperPaga.svg"),
              "kds":require("../icons/KDSPaga.svg"),
              "expense":require("../icons/expensePaga.svg"),
              "reservation":require("../icons/reservationPaga.svg"),
            },
            "miscIcons": {
                "downArrow":require("../icons/downArrowPaga.svg"),
                "backArrow":require("../icons/backArrowPaga.svg"),
                "editIcon":require("../icons/editPaga.svg"),
                "plusIcon":require("../icons/plusPaga.svg"),
                "crossIcon":require("../icons/cross-utap.svg"),
                "addCustomer":require("../icons/addCustomerPaga.svg"),
                "trash":require("../icons/trashPaga.svg"),
                "sortIcon":require("../icons/sortPaga.svg"),
                "gridIcon":require("../icons/gridPaga.svg"),
                "listIcon":require("../icons/listPaga.svg"),
                "plusIcon":require("../icons/plus-utap.svg"),
                "minusIcon":require("../icons/minusPaga.svg"),
                "filterIcon":require("../icons/filter-utap.svg"),
                "success":require("../icons/success-utap.svg"),
                "calendar":require("../icons/calenderPaga.svg"),
            },
            suspenseLoaderUrl: "https://quebuster.s3.ap-south-1.amazonaws.com/website/assets/ezgif.com-speed.gif",
            filter: "invert(15%) sepia(81%) saturate(5245%) hue-rotate(10deg) brightness(88%) contrast(120%)",
            languageOptions:["en"]
        }
        