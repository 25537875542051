export const LOGIN = "LOGIN"
export const LOGIN_FAIL = "LOGIN_FAIL"
export const LOGOUT = "LOGOUT"
export const CHECK_LOGIN = "CHECK_LOGIN"
export const CHECK_LOGIN_FAIL = "CHECK_LOGIN_FAIL"
export const SIGN_UP = "SIGN_UP"
export const OPEN_OTP = "OPEN_OTP"
export const CLOSE_OTP = "CLOSE_OTP"
export const REGISTERATION_SUCCESS = "REGISTERATION_SUCCESS"
export const GET_STARTED = "GET_STARTED"
export const ERRORS = "ERRORS"

export const TOGGLE_MODULE_SIDEBAR = 'TOGGLE_MODULE_SIDEBAR';
export const OPENSIDEBAR_FOR_HOME = 'OPENSIDEBAR_FOR_HOME';
export const SHOW_POPOVER = 'SHOW_POPOVER';
export const HIDE_POPOVER = 'HIDE_POPOVER';
export const HIDE_MODULE_SIDEBAR = 'HIDE_MODULE_SIDEBAR';
export const CHANGE_GLOBAL_PATHNAME = 'CHANGE_GLOBAL_PATHNAME';
export const SHOW_GLOBAL_DATERANGEMODAL = 'SHOW_GLOBAL_DATERANGEMODAL';
export const HIDE_GLOBAL_DATERANGEMODAL = 'HIDE_GLOBAL_DATERANGEMODAL';
export const SHOW_SUBMODULE_SIDEBAR = 'SHOW_SUBMODULE_SIDEBAR';
export const HIDE_SUBMODULE_SIDEBAR = 'HIDE_SUBMODULE_SIDEBAR';
export const TOGGLE_SUBMODULE_SIDEBAR = 'TOGGLE_SUBMODULE_SIDEBAR';

export const CHART_DATA = "CHART_DATA";

export const NEW_CHART_DATA = "NEW_CHART_DATA";

export const STORE_ROOM_ID = "STORE_ROOM_ID"

export const TAX_LIST = 'TAX_LIST';
export const CHARGES_LIST = 'CHARGES_LIST';
export const DISCOUNTS_LIST = 'DISCOUNTS_LIST';
export const PROD_VARIANT_LIST = 'PROD_VARIANT_LIST';
export const MODIFIERS_LIST = 'MODIFIERS_LIST';
export const MODIFIERS_PROD_LIST = 'MODIFIERS_PROD_LIST';
export const MODIFIERS_GROUP_LIST = 'MODIFIERS_GROUP_LIST';
export const MAPPED_MODIFIERS_LIST = 'MAPPED_MODIFIERS_LIST';

export const CATEGORIES_FOR_PROD = 'CATEGORIES_FOR_PROD';
export const SUB_CATEGORIES_FOR_PROD = 'SUB_CATEGORIES_FOR_PROD';

export const BRANDS_FOR_PROD = 'brandsForProd';
export const SIZES = 'sizes';
export const COLORS = 'colors';
export const GET_COLORS="GET_COLORS";
export const GET_SIZES="GET_SIZES";
export const VOUCHERS='vouchers';
export const CHARGES = 'getCharges';
export const BATCHES = 'getBatches';
export const PRODUCTS = 'productsChain';
export const PRODUCTS_TRIMMED = 'productsListTrimmedWeb';
export const VENDORS = 'getVendorsWeb';
export const UNITS = 'unitsWeb';
export const CATEGORIES = 'CATEGORIES';
export const COMBOS = 'COMBOS';
export const GET_PROD_IMAGE = 'GET_PROD_IMAGE'
export const BRANDS = 'BRANDS';
export const BRANDS_FORPROD=BRANDS_FORPROD;
export const ORDER_DETAIL = 'ORDER_DETAIL';

export const CATEGORY_CREATED = 'CATEGORY_CREATED';
export const CATEGORY_CREATED_FAILED = 'CATEGORY_CREATED_FAILED';

export const ALL_PRODS_DETAILS_LIST = 'ALL_PRODS_DETAILS_LIST';
export const ALL_PRODS_LIST = 'ALL_PRODS_LIST';
export const GET_FLOORS_TABLE='GET_FLOORS_TABLE';

export const MANUFACTURERS = "MANUFACTURERS";

export const EDITMANUFACTURER_REQUEST = "EDITMANUFACTURER_REQUEST";
export const EDITMANUFACTURER_SUCCESS = "EDITMANUFACTURER_SUCCESS";
export const EDITMANUFACTURER_FAILURE  = "EDITMANUFACTURER_FAILURE";

export const CREATEMANUFACTURER_REQUEST = "CREATEMANUFACTURER_REQUEST";
export const CREATEMANUFACTURER_SUCCESS = "CREATEMANUFACTURER_SUCCESS";
export const CREATEMANUFACTURER_FAILURE  = "CREATEMANUFACTURER_FAILURE";

export const DELETEMANUFACTURER_REQUEST = "DELETEMANUFACTURER_REQUEST";
export const DELETEMANUFACTURER_SUCCESS = "DELETEMANUFACTURER_SUCCESS";
export const DELETEMANUFACTURER_FAILURE  = "DELETEMANUFACTURER_FAILURE";

//set lang
export const SET_LANGUAGE="SET_LANGUAGE";

//Inventory/StockIn
export const GET_INVENTORY_REQUEST = 'GET_INVENTORY_REQUEST';
export const GET_INVENTORY_SUCCESS = 'GET_INVENTORY_SUCCESS';
export const GET_INVENTORY_FAILURE = 'GET_INVENTORY_FAILURE';

export const STOCK_FORM_DATA = 'STOCK_FORM_DATA';
export const SET_CART_DATA='SET_CART_DATA';
export const UPDATE_ITEM_QTY='UPDATE_ITEM_QTY';
export const GET_UNITS_LIST = 'GET_UNITS_LIST';
export const GET_PURCHASE_ORDERS = 'GET_PURCHASE_ORDERS';
export const GET_PURCHASEORDER_DETAILS= 'GET_PURCHASEORDER_DETAILS';
export const GET_REQUISITION_ORDERS = 'GET_REQUISITION_ORDERS';
export const GET_VENDORS_LISTING='GET_VENDORS_LISTING';
export const CART_TRANSACTION = "CART_TRANSACTION";
export const GET_STOCKTRANSACTION_HISTORY = "GET_STOCKTRANSACTION_HISTOY";
export const GET_REMARKS_LIST="GET_REMARKS_LIST";
export const SET_VALIDPRODS="SET_VALIDPRODS";
export const VALIDATION_REMARKS="VALIDATION_REMARKS";
export const GET_REQUISITION_HISTORY="GET_REQUISITION_HISTORY";
export const MAKE_STOCK_REQUISITION="MAKE_STOCK_REQUISITION";
export const GET_REQUISITION_IDS="GET_REQUISITION_IDS";

//Inventory/Vendor
export const GET_VENDORS_SOURCE_MAPPING = 'GET_VENDORS_SOURCE_MAPPING';

//vendors
export const ADD_VENDOR="ADD_VENDOR";
export const GET_VENDOR_INVOICES="GET_VENDOR_INVOICES";
export const GET_INVOICE_PAYINFO="GET_INVOICE_PAYINFO";
export const ADD_VENDOR_INVOICE="ADD_VENDOR_INVOICE";
export const EDIT_VENDOR_INVOICE="EDIT_VENDOR_INVOICE";
export const ADD_VENDOR_INVOICE_PAYMENT="ADD_VENDOR_INVOICE_PAYMENT";
export const EDIT_VENDOR_INVOICE_PAYMENT="EDIT_VENDOR_INVOICE_PAYMENT";

//floor/table/room
export const CREATE_FLOOR="CREATE_FLOOR";
export const UPDATE_FLOOR="UPDATE_FLOOR";
export const CREATE_TABLE="CREATE_TABLE";
export const CREATE_ROOM="CREATE_ROOM";
export const UPDATE_ROOM="UPDATE_ROOM";
export const UPDATE_TABLE="UPDATE_TABLE";

export const DELETE_FLOOR_WEB="DELETE_FLOOR_WEB";
export const DELETE_ROOM_WEB="DELETE_ROOM_WEB";
export const DELETE_TABLE_WEB="DELETE_TABLE_WEB";

//incomeheads
export const GET_INCOMEHEADS="GET_INCOMEHEADS";
export const GET_PRODUCTS_BYCHAIN="GET_PRODUCTS_BYCHAIN";
export const GET_PRODUCTS_TRIMMED_BYCHAIN="GET_PRODUCTS_TRIMMED_BYCHAIN";
export const GET_PRODUCTS_TRIMMED_WITH_COMBOS_BYCHAIN="GET_PRODUCTS_TRIMMED_WITH_COMBOS_BYCHAIN";
export const GET_PRODUCTS_PROMOTION="GET_PRODUCTS_PROMOTION";
export const GET_PAYMENT_LIST="GET_PAYMENT_LIST";

export const CREATE_INCOMEHEAD="CREATE_INCOMEHEAD";
export const EDIT_INCOMEHEAD="EDIT_INCOMEHEAD";
//services //product group
export const GET_SERVICES="GET_SERVICES";
export const GET_TREATMENT_TYPES="GET_TREATMENT_TYPES";
export const GET_SERVICE_GROUPS="GET_SERVICE_GROUPS";
export const GET_SERVICE_GROUPS_CATEGORIES="GET_SERVICE_GROUPS_CATEGORIES";
export const GET_SERVICE_SLOTS="GET_SERVICE_SLOTS";
export const GET_CHAIN_TAXES="GET_CHAIN_TAXES";
export const GET_CHAIN_CHARGES="GET_CHAIN_CHARGES";
export const GET_PROD_BATCH_VARIANTS="GET_PROD_BATCH_VARIANTS";
export const UPDATE_PRODUCT_GROUP="UPDATE_PRODUCT_GROUP";
export const GET_PRODUCT_CATALOGUE="GET_PRODUCT_CATALOGUE";
export const CREATE_PRODUCT_GROUP="CREATE_PRODUCT_GROUP";
export const CREATE_BULK_SERVICE_GROUPS="CREATE_BULK_SERVICE_GROUPS"
export const EDIT_BULK_SERVICE_GROUPS="EDIT_BULK_SERVICE_GROUPS"

//units
export const GET_BASE_UNITS="GET_BASE_UNITS";
export const ADD_UNIT_REQUEST="ADD_UNIT_REQUEST";
export const ADD_UNIT_SUCCESS="ADD_UNIT_SUCCESS";
export const ADD_UNIT_FAILURE="ADD_UNIT_FAILURE";
export const EDIT_UNIT_REQUEST="ADD_UNIT_REQUEST";
export const EDIT_UNIT_SUCCESS="ADD_UNIT_SUCCESS";
export const EDIT_UNIT_FAILURE="ADD_UNIT_FAILURE";

//memberships
export const GET_MEMBERSHIPS_REQUEST="GET_MEMBERSHIPS_REQUEST";
export const GET_MEMBERSHIPS_SUCCESS="GET_MEMBERSHIPS_SUCCESS";
export const GET_MEMBERSHIPS_FAILURE="GET_MEMBERSHIPS_FAILURE";
export const GET_SUBCATEGORY="GET_SUBCATEGORY";
export const CREATE_MEMBERSHIP="CREATE_MEMBERSHIP";
export const EDIT_MEMBERSHIP="EDIT_MEMBERSHIP";
export const DELETE_MEMBERSHIP="DELETE_MEMBERSHIP";

//initloader
export const INIT_LOADER="INIT_LOADER";
export const HALT_LOADER="HALT_LOADER";

//init notifier
export const SUCCESS_NOTIFIER="SUCCESS_NOTIFIER";
export const ERROR_NOTIFIER="ERROR_NOTIFIER";
export const HALT_NOTIFIER="HALT_NOTIFIER";

//assign prods store
export const EDIT_STOREPROD_REQUEST="EDIT_STOREPROD_REQUEST";
export const EDIT_STOREPROD_SUCCESS="EDIT_STOREPROD_SUCCESS";
export const EDIT_STOREPROD_FAILURE="EDIT_STOREPROD_FAILURE";
export const GET_NONSTORE_PRODS_REQUEST="GET_NONSTORE_PRODS_REQUEST";
export const GET_NONSTORE_PRODS_SUCCESS="GET_NONSTORE_PRODS_SUCCESS";
export const GET_NONSTORE_PRODS_FAILURE="GET_NONSTORE_PRODS_FAILURE";
export const ADD_STOREPROD="ADD_STOREPROD";

//regions
export const FETCH_REGIONS_REQUEST="FETCH_REGIONS_REQUEST";
export const FETCH_REGIONS_SUCCESS="FETCH_REGIONS_SUCCESS";
export const FETCH_REGIONS_FAILURE="FETCH_REGIONS_FAILURE"; 

export const CREATE_REGION="CREATE_REGION";
export const UPDATE_REGION="UPDATE_REGION";
export const DELETE_REGION="DELETE_REGION";

//remarks
export const FETCH_REMARKS_REQUEST="FETCH_REMARKS_REQUEST";
export const FETCH_REMARKS_SUCCESS="FETCH_REMARKS_SUCCESS";
export const FETCH_REMARKS_FAILURE="FETCH_REMARKS_FAILURE"; 

export const CREATE_REMARKS="CREATE_REMARKS";
export const UPDATE_REMARKS="UPDATE_REMARKS";
export const DELETE_REMARKS="DELETE_REMARKS";

//entity
export const DELETE_ENTITY="DELETE_ENTITY";

export const DELETE_ALL_ENTITY = "DELETE_ALL_ENTITY";

export const GET_PRTRCT_SETTINGS_REQUEST="GET_PRTRCT_SETTINGS_REQUEST";
export const GET_PRTRCT_SETTINGS_SUCCESS="GET_PRTRCT_SETTINGS_SUCCESS";
export const GET_PRTRCT_SETTINGS_FAILURE="GET_PRTRCT_SETTINGS_FAILURE";
export const UPDATE_PRTRCT_SETTINGS_REQUEST="UPDATE_PRTRCT_SETTINGS_REQUEST";
export const UPDATE_PRTRCT_SETTINGS_SUCCESS="UPDATE_PRTRCT_SETTINGS_SUCCESS";
export const UPDATE_PRTRCT_SETTINGS_FAILURE="UPDATE_PRTRCT_SETTINGS_FAILURE";

export const GET_CHAIN_INFO_REQUEST="GET_CHAIN_INFO_REQUEST";
export const GET_CHAIN_INFO_SUCCESS="GET_CHAIN_INFO_SUCCESS";
export const GET_CHAIN_INFO_FAILURE="GET_CHAIN_INFO_FAILURE";

export const GET_CHAINBILL_INFO_REQUEST="GET_CHAINBILL_INFO_REQUEST";
export const GET_CHAINBILL_INFO_SUCCESS="GET_CHAINBILL_INFO_SUCCESS";
export const GET_CHAINBILL_INFO_FAILURE="GET_CHAINBILL_INFO_FAILURE";
export const UPDATE_BILL_ADDRESS_REQUEST="UPDATE_BILL_ADDRESS_REQUEST";
export const UPDATE_BILL_ADDRESS_SUCCESS="UPDATE_BILL_ADDRESS_SUCCESS";
export const UPDATE_BILL_ADDRESS_FAILURE="UPDATE_BILL_ADDRESS_FAILURE";

export const GET_STOREINFO_REQUEST="GET_STOREINFO_REQUEST";
export const GET_STOREINFO_SUCCESS="GET_STOREINFO_SUCCESS";
export const GET_STOREINFO_FAILURE="GET_STOREINFO_FAILURE";
export const EDIT_STOREINFO="EDIT_STOREINFO";
export const CREATE_STORE="CREATE_STORE";


export const GET_COUNTERINFO_REQUEST="GET_COUNTERINFO_REQUEST";
export const GET_COUNTERINFO_SUCCESS="GET_COUNTERINFO_SUCCESS";
export const GET_COUNTERINFO_FAILURE="GET_COUNTERINFO_FAILURE";


export const GET_PRODUCTLIST_REQUEST="GET_PRODUCTLIST_REQUEST";
export const GET_PRODUCTLIST_SUCCESS="GET_PRODUCTLIST_SUCCESS";
export const GET_PRODUCTLIST_FAILURE="GET_PRODUCTLIST_FAILURE";


export const GET_EDITCOUNTERLIST_REQUEST="GET_EDITCOUNTERLIST_REQUEST";
export const GET_EDITCOUNTERLIST_SUCCESS="GET_EDITCOUNTERLIST_SUCCESS";
export const GET_EDITCOUNTERLIST_FAILURE="GET_EDITCOUNTERLIST_FAILURE";


export const GET_CHAINSTORELIST_REQUEST="GET_CHAINSTORELIST_REQUEST";
export const GET_CHAINSTORELIST_SUCCESS="GET_CHAINSTORELIST_SUCCESS";
export const GET_CHAINSTORELIST_FAILURE="GET_CHAINSTORELIST_FAILURE";
export const UPDATE_CHAIN_INFO_REQUEST="UPDATE_CHAIN_INFO_REQUEST";
export const UPDATE_CHAIN_INFO_SUCCESS="UPDATE_CHAIN_INFO_SUCCESS";
export const UPDATE_CHAIN_INFO_FAILURE="UPDATE_CHAIN_INFO_FAILURE";
export const ADD_BILL_ADDRESS_REQUEST="ADD_BILL_ADDRESS_REQUEST";
export const ADD_BILL_ADDRESS_SUCCESS="ADD_BILL_ADDRESS_SUCCESS";
export const ADD_BILL_ADDRESS_FAILURE="ADD_BILL_ADDRESS_FAILURE";

//sms
export const GET_SMS_SETTINGS_REQUEST="GET_SMS_SETTINGS_REQUEST";
export const GET_SMS_SETTINGS_SUCCESS="GET_SMS_SETTINGS_SUCCESS";
export const GET_SMS_SETTINGS_FAILURE="GET_SMS_SETTINGS_FAILURE";
export const UPDATE_SMS_SETTINGS="UPDATE_SMS_SETTINGS";
export const UPDATE_ORDER_SMS_REQUEST="UPDATE_ORDER_SMS_REQUEST";
export const UPDATE_ORDER_SMS_SUCCESS="UPDATE_ORDER_SMS_SUCCESS";
export const UPDATE_ORDER_SMS_FAILURE="UPDATE_ORDER_SMS_FAILURE";

export const GET_SMS_RATES_REQUEST="GET_SMS_RATES_REQUEST";
export const GET_SMS_RATES_SUCCESS="GET_SMS_RATES_SUCCESS";
export const GET_SMS_RATES_FAILURE="GET_SMS_RATES_FAILURE";

//appsettings
export const GET_APP_SETTINGS_REQUEST="GET_APP_SETTINGS_REQUEST";
export const GET_APP_SETTINGS_SUCCESS="GET_APP_SETTINGS_SUCCESS";
export const GET_APP_SETTINGS_FAILURE="GET_APP_SETTINGS_FAILURE";
export const UPDATE_APP_SETTINGS="UPDATE_APP_SETTINGS";

export const GET_DEVICE_LOGS_REQUEST="GET_DEVICE_LOGS_REQUEST";
export const GET_DEVICE_LOGS_SUCCESS="GET_DEVICE_LOGS_SUCCESS";
export const GET_DEVICE_LOGS_FAILURE="GET_DEVICE_LOGS_FAILURE";

export const GET_PRINTER_TERMINAL="GET_PRINTER_TERMINAL";

//INVENTORYBATCH
export const GET_BATCHES_REQUEST="GET_BATCHES_REQUEST";
export const GET_BATCHES_SUCCESS="GET_BATCHES_SUCCESS";
export const GET_BATCHES_FAILURE="GET_BATCHES_FAILURE";
export const GET_BATCH_PRODUCTS="GET_BATCH_PRODUCTS";
export const GET_BATCH_PRODUCTS_LIST="GET_BATCH_PRODUCTS_LIST";
export const CREATE_BATCH_TRANSACTION="CREATE_BATCH_TRANSACTION";
export const EDIT_BATCH_TRANSACTION="EDIT_BATCH_TRANSACTION";

//loyalty
export const FETCH_CUSTOMERS_REQUEST = "FETCH_CUSTOMERS_REQUEST";
export const FETCH_CUSTOMERS_SUCCESS = "FETCH_CUSTOMERS_SUCCESS";
export const FETCH_CUSTOMERS_FAILURE = "FETCH_CUSTOMERS_FAILURE";
export const ADD_BULKCUSTOMERS = "ADD_BULKCUSTOMERS";
export const EDIT_BULKCUSTOMERS = "EDIT_BULKCUSTOMERS";
export const SEND_CAMPAIGNSMS="SEND_CAMPAIGNSMS";

export const GET_LOYALTYINFO_REQUEST="GET_LOYALTYINFO_REQUEST";
export const GET_LOYALTYINFO_SUCCESS="GET_LOYALTYINFO_SUCCESS";
export const GET_LOYALTYINFO_FAILURE="GET_LOYALTYINFO_FAILURE";

export const GET_LOYALTYPRODUCTS="GET_LOYALTYPRODUCTS";
export const GET_CONFIGLOYALTYPRODUCTS="GET_CONFIGLOYALTYPRODUCTS";
export const UPDATE_LOYALTY_SETTINGS="UPDATE_LOYALTY_SETTINGS";

export const FETCH_MESSAGE_INFO_REQUEST="FETCH_MESSAGE_INFO_REQUEST";
export const FETCH_MESSAGE_INFO_FAILURE="FETCH_MESSAGE_INFO_FAILURE";
export const FETCH_MESSAGE_INFO_SUCCESS="FETCH_MESSAGE_INFO_SUCCESS";

export const FETCH_CUSTOMERS_BALANCE_REQUEST = "FETCH_CUSTOMERS_BALANCE_REQUEST";
export const FETCH_CUSTOMERS_BALANCE_SUCCESS = "FETCH_CUSTOMERS_BALANCE_SUCCESS";
export const FETCH_CUSTOMERS_BALANCE_FAILURE = "FETCH_CUSTOMERS_BALANCE_FAILURE";

export const FETCH_CUSTOMER_LEDGER_REQUEST = "FETCH_CUSTOMER_LEDGER_REQUEST";
export const FETCH_CUSTOMER_LEDGER_SUCCESS = "FETCH_CUSTOMER_LEDGER_SUCCESS";
export const FETCH_CUSTOMER_LEDGER_FAILURE = "FETCH_CUSTOMER_LEDGER_FAILURE";

export const FETCH_REPORT_CUSTOMER_SALES_REQUEST = "FETCH_REPORT_CUSTOMER_SALES_REQUEST";
export const FETCH_REPORT_CUSTOMER_SALES_SUCCESS = "FETCH_REPORT_CUSTOMER_SALES_SUCCESS";
export const FETCH_REPORT_CUSTOMER_SALES_FAILURE = "FETCH_REPORT_CUSTOMER_SALES_FAILURE";

export const FETCH_CUSTOMER_DASHBOARD_REQUEST = "FETCH_CUSTOMER_DASHBOARD_REQUEST";
export const FETCH_CUSTOMER_DASHBOARD_SUCCESS = "FETCH_CUSTOMER_DASHBOARD_SUCCESS";
export const FETCH_CUSTOMER_DASHBOARD_FAILURE = "FETCH_CUSTOMER_DASHBOARD_FAILURE";

//liscenses
export const GET_CHAINLISCENSES_REQUEST="GET_CHAINLISCENSES_REQUEST";
export const GET_CHAINLISCENSES_SUCCESS="GET_CHAINLISCENSES_SUCCESS";
export const GET_CHAINLISCENSES_FAILURE="GET_CHAINLISCENSES_FAILURE";





//po types
export const FETCH_PURCHASE_ORDERS_REQUEST = 'FETCH_PURCHASE_ORDERS_REQUEST';
export const FETCH_PURCHASE_ORDERS_SUCCESS = 'FETCH_PURCHASE_ORDERS_SUCCESS';
export const FETCH_PURCHASE_ORDERS_FAILURE = 'FETCH_PURCHASE_ORDERS_FAILURE';

export const FETCH_VENDORS_REQUEST = 'FETCH_VENDORS_REQUEST';
export const FETCH_VENDORS_SUCCESS = 'FETCH_VENDORS_SUCCESS';
export const FETCH_VENDORS_FAILURE = 'FETCH_VENDORS_FAILURE';

export const FETCH_STORE_LIST_REQUEST = 'FETCH_STORE_LIST_REQUEST';
export const FETCH_STORE_LIST_SUCCESS = 'FETCH_STORE_LIST_SUCCESS';
export const FETCH_STORE_LIST_FAILURE = 'FETCH_STORE_LIST_FAILURE';

export const FETCH_WARE_LIST_REQUEST = 'FETCH_WARE_LIST_REQUEST';
export const FETCH_WARE_LIST_SUCCESS = 'FETCH_WARE_LIST_SUCCESS';
export const FETCH_WARE_LIST_FAILURE = 'FETCH_WARE_LIST_FAILURE';

export const FETCH_PRODUCTS_REQUEST = 'FETCH_PRODUCTS_REQUEST';
export const FETCH_PRODUCTS_SUCCESS = 'FETCH_PRODUCTS_SUCCESS';
export const FETCH_PRODUCTS_FAILURE = 'FETCH_PRODUCTS_FAILURE';

export const FETCH_UNITS_REQUEST = 'FETCH_UNITS_REQUEST';
export const FETCH_UNITS_SUCCESS = 'FETCH_UNITS_SUCCESS';
export const FETCH_UNITS_FAILURE = 'FETCH_UNITS_FAILURE';

export const FETCH_TAXES_REQUEST = 'FETCH_TAXES_REQUEST';
export const FETCH_TAXES_SUCCESS = 'FETCH_TAXES_SUCCESS';
export const FETCH_TAXES_FAILURE = 'FETCH_TAXES_FAILURE';

export const CREATE_PURCHASE_ORDER_REQUEST = 'CREATE_PURCHASE_ORDER_REQUEST';
export const CREATE_PURCHASE_ORDER_SUCCESS = 'CREATE_PURCHASE_ORDER_SUCCESS';
export const CREATE_PURCHASE_ORDER_FAILURE = 'CREATE_PURCHASE_ORDER_FAILURE';

export const FETCH_PURCHASE_ORDER_DETAIL_REQUEST = 'FETCH_PURCHASE_ORDER_DETAIL_REQUEST';
export const FETCH_PURCHASE_ORDER_DETAIL_SUCCESS = 'FETCH_PURCHASE_ORDER_DETAIL_SUCCESS';
export const FETCH_PURCHASE_ORDER_DETAIL_FAILURE = 'FETCH_PURCHASE_ORDER_DETAIL_FAILURE';

export const SELECT_DATE_RANGE = 'SELECT_DATE_RANGE';
export const SET_MODAL_SHOW = 'SET_MODAL_SHOW';
export const ADD_TO_CONFIRM_PRODUCTS = 'ADD_TO_CONFIRM_PRODUCTS'
export const REMOVE_FROM_CONFIRM_PRODUCTS = 'REMOVE_FROM_CONFIRM_PRODUCTS'
export const ADD_BULK_CONFIRM_PRODUCTS = 'ADD_BULK_CONFIRM_PRODUCTS'





// PI types

export const FETCH_PI_LIST_REQUEST = 'FETCH_PI_LIST_REQUEST';
export const FETCH_PI_LIST_SUCCESS = 'FETCH_PI_LIST_SUCCESS';
export const FETCH_PI_LIST_FAILURE = 'FETCH_PI_LIST_FAILURE';

export const FETCH_USERS_LIST_REQUEST = 'FETCH_USERS_LIST_REQUEST';
export const FETCH_USERS_LIST_SUCCESS = 'FETCH_USERS_LIST_SUCCESS';
export const FETCH_USERS_LIST_FAILURE = 'FETCH_USERS_LIST_FAILURE';

export const FETCH_CUSTOMERS_LIST_REQUEST = 'FETCH_CUSTOMERS_LIST_REQUEST';
export const FETCH_CUSTOMERS_LIST_SUCCESS = 'FETCH_CUSTOMERS_LIST_SUCCESS';
export const FETCH_CUSTOMERS_LIST_FAILURE = 'FETCH_CUSTOMERS_LIST_FAILURE';

export const FETCH_DEVICES_LIST_REQUEST = 'FETCH_DEVICES_LIST_REQUEST';
export const FETCH_DEVICES_LIST_SUCCESS = 'FETCH_DEVICES_LIST_SUCCESS';
export const FETCH_DEVICES_LIST_FAILURE = 'FETCH_DEVICES_LIST_FAILURE';

export const FETCH_INVOICES_REQUEST = 'FETCH_INVOICES_REQUEST';
export const FETCH_INVOICES_SUCCESS = 'FETCH_INVOICES_SUCCESS';
export const FETCH_INVOICES_FAILURE = 'FETCH_INVOICES_FAILURE';

export const FETCH_PI_DETAIL_REQUEST = 'FETCH_PI_DETAIL_REQUEST';
export const FETCH_PI_DETAIL_SUCCESS = 'FETCH_PI_DETAIL_SUCCESS';
export const FETCH_PI_DETAIL_FAILURE = 'FETCH_PI_DETAIL_FAILURE';

export const FETCH_PRODUCTS_IN_PI_REQUEST = 'FETCH_PRODUCTS_IN_PI_REQUEST';
export const FETCH_PRODUCTS_IN_PI_SUCCESS = 'FETCH_PRODUCTS_IN_PI_SUCCESS';
export const FETCH_PRODUCTS_IN_PI_FAILURE = 'FETCH_PRODUCTS_IN_PI_FAILURE';

export const FETCH_ORDERS_REQUEST = 'FETCH_ORDERS_REQUEST';
export const FETCH_ORDERS_SUCCESS = 'FETCH_ORDERS_SUCCESS';
export const FETCH_ORDERS_FAILURE = 'FETCH_ORDERS_FAILURE';

export const FETCH_ODR_REQUEST = 'FETCH_ODR_REQUEST';
export const FETCH_ODR_SUCCESS = 'FETCH_ODR_SUCCESS';
export const FETCH_ODR_FAILURE = 'FETCH_ODR_FAILURE';

export const FETCH_DOC_REQUEST = 'FETCH_DOC_REQUEST';
export const FETCH_DOC_SUCCESS = 'FETCH_DOC_SUCCESS';
export const FETCH_DOC_FAILURE = 'FETCH_DOC_FAILURE';

export const EMPTY_ORDERS_STATE = 'EMPTY_ORDERS_STATE';

export const FETCH_CATEGORIES_FOR_PRODUCTS_REQUEST = 'FETCH_CATEGORIES_FOR_PRODUCTS_REQUEST';
export const FETCH_CATEGORIES_FOR_PRODUCTS_SUCCESS = 'FETCH_CATEGORIES_FOR_PRODUCTS_SUCCESS';
export const FETCH_CATEGORIES_FOR_PRODUCTS_FAILURE = 'FETCH_CATEGORIES_FOR_PRODUCTS_FAILURE';

export const FETCH_BRANDS_FOR_PRODUCTS_REQUEST = 'FETCH_BRANDS_FOR_PRODUCTS_REQUEST';
export const FETCH_BRANDS_FOR_PRODUCTS_SUCCESS = 'FETCH_BRANDS_FOR_PRODUCTS_SUCCESS';
export const FETCH_BRANDS_FOR_PRODUCTS_FAILURE = 'FETCH_BRANDS_FOR_PRODUCTS_FAILURE';

export const FETCH_ORDER_DETAIL_REQUEST = 'FETCH_ORDER_DETAIL_REQUEST';
export const FETCH_ORDER_DETAIL_SUCCESS = 'FETCH_ORDER_DETAIL_SUCCESS';
export const FETCH_ORDER_DETAIL_FAILURE = 'FETCH_ORDER_DETAIL_FAILURE';

export const FETCH_PRODUCTS_IN_ORDER_REQUEST = 'FETCH_PRODUCTS_IN_ORDER_REQUEST';
export const FETCH_PRODUCTS_IN_ORDER_SUCCESS = 'FETCH_PRODUCTS_IN_ORDER_SUCCESS';
export const FETCH_PRODUCTS_IN_ORDER_FAILURE = 'FETCH_PRODUCTS_IN_ORDER_FAILURE';

export const FETCH_ORDER_TAX_BREAKUP_REQUEST = 'FETCH_ORDER_TAX_BREAKUP_REQUEST';
export const FETCH_ORDER_TAX_BREAKUP_SUCCESS = 'FETCH_ORDER_TAX_BREAKUP_SUCCESS';
export const FETCH_ORDER_TAX_BREAKUP_FAILURE = 'FETCH_ORDER_TAX_BREAKUP_FAILURE';

export const FETCH_PRODUCT_TAX_BREAKUP_REQUEST = 'FETCH_PRODUCT_TAX_BREAKUP_REQUEST';
export const FETCH_PRODUCT_TAX_BREAKUP_SUCCESS = 'FETCH_PRODUCT_TAX_BREAKUP_SUCCESS';
export const FETCH_PRODUCT_TAX_BREAKUP_FAILURE = 'FETCH_PRODUCT_TAX_BREAKUP_FAILURE';

export const FETCH_VOID_ORDERS_REQUEST = 'FETCH_VOID_ORDERS_REQUEST';
export const FETCH_VOID_ORDERS_SUCCESS = 'FETCH_VOID_ORDERS_SUCCESS';
export const FETCH_VOID_ORDERS_FAILURE = 'FETCH_VOID_ORDERS_FAILURE';

export const FETCH_STORE_SALES_REQUEST = 'FETCH_STORE_SALES_REQUEST';
export const FETCH_STORE_SALES_SUCCESS = 'FETCH_STORE_SALES_SUCCESS';
export const FETCH_STORE_SALES_FAILURE = 'FETCH_STORE_SALES_FAILURE';

export const FETCH_STORE_PRODUCT_SALES_REQUEST = 'FETCH_STORE_PRODUCT_SALES_REQUEST';
export const FETCH_STORE_PRODUCT_SALES_SUCCESS = 'FETCH_STORE_PRODUCT_SALES_SUCCESS';
export const FETCH_STORE_PRODUCT_SALES_FAILURE = 'FETCH_STORE_PRODUCT_SALES_FAILURE';

export const FETCH_REGION_SALES_REQUEST = 'FETCH_REGION_SALES_REQUEST';
export const FETCH_REGION_SALES_SUCCESS = 'FETCH_REGION_SALES_SUCCESS';
export const FETCH_REGION_SALES_FAILURE = 'FETCH_REGION_SALES_FAILURE';

export const FETCH_PRODUCT_SALES_REQUEST = 'FETCH_PRODUCT_SALES_REQUEST';
export const FETCH_PRODUCT_SALES_SUCCESS = 'FETCH_PRODUCT_SALES_SUCCESS';
export const FETCH_PRODUCT_SALES_FAILURE = 'FETCH_PRODUCT_SALES_FAILURE';

export const FETCH_CATEGORY_SALES_REQUEST = 'FETCH_CATEGORY_SALES_REQUEST';
export const FETCH_CATEGORY_SALES_SUCCESS = 'FETCH_CATEGORY_SALES_SUCCESS';
export const FETCH_CATEGORY_SALES_FAILURE = 'FETCH_CATEGORY_SALES_FAILURE';

export const FETCH_BRAND_SALES_REQUEST = 'FETCH_BRAND_SALES_REQUEST';
export const FETCH_BRAND_SALES_SUCCESS = 'FETCH_BRAND_SALES_SUCCESS';
export const FETCH_BRAND_SALES_FAILURE = 'FETCH_BRAND_SALES_FAILURE';

export const FETCH_STAFF_SALES_REQUEST = 'FETCH_STAFF_SALES_REQUEST';
export const FETCH_STAFF_SALES_SUCCESS = 'FETCH_STAFF_SALES_SUCCESS';
export const FETCH_STAFF_SALES_FAILURE = 'FETCH_STAFF_SALES_FAILURE';

export const FETCH_CUSTOMER_SALES_REQUEST = 'FETCH_CUSTOMER_SALES_REQUEST';
export const FETCH_CUSTOMER_SALES_SUCCESS = 'FETCH_CUSTOMER_SALES_SUCCESS';
export const FETCH_CUSTOMER_SALES_FAILURE = 'FETCH_CUSTOMER_SALES_FAILURE';

export const FETCH_DEVICE_SALES_REQUEST = 'FETCH_DEVICE_SALES_REQUEST';
export const FETCH_DEVICE_SALES_SUCCESS = 'FETCH_DEVICE_SALES_SUCCESS';
export const FETCH_DEVICE_SALES_FAILURE = 'FETCH_DEVICE_SALES_FAILURE';

export const FETCH_IH_SALES_REQUEST = 'FETCH_IH_SALES_REQUEST';
export const FETCH_IH_SALES_SUCCESS = 'FETCH_IH_SALES_SUCCESS';
export const FETCH_IH_SALES_FAILURE = 'FETCH_IH_SALES_FAILURE';

export const FETCH_RTP_REQUEST = 'FETCH_RTP_REQUEST';
export const FETCH_RTP_SUCCESS = 'FETCH_RTP_SUCCESS';
export const FETCH_RTP_FAILURE = 'FETCH_RTP_FAILURE';

export const FETCH_DSR_REQUEST = 'FETCH_DSR_REQUEST';
export const FETCH_DSR_SUCCESS = 'FETCH_DSR_SUCCESS';
export const FETCH_DSR_FAILURE = 'FETCH_DSR_FAILURE';

export const FETCH_DPB_REQUEST = 'FETCH_DPB_REQUEST';
export const FETCH_DPB_SUCCESS = 'FETCH_DPB_SUCCESS';
export const FETCH_DPB_FAILURE = 'FETCH_DPB_FAILURE';

export const CHECK_LIST_OF_ORDER_REQUEST = 'CHECK_LIST_OF_ORDER_REQUEST';
export const CHECK_LIST_OF_ORDER_SUCCESS = 'CHECK_LIST_OF_ORDER_SUCCESS';
export const CHECK_LIST_OF_ORDER_FAILURE = 'CHECK_LIST_OF_ORDER_FAILURE';

export const FETCH_DOB_REQUEST = 'FETCH_DOB_REQUEST';
export const FETCH_DOB_SUCCESS = 'FETCH_DOB_SUCCESS';
export const FETCH_DOB_FAILURE = 'FETCH_DOB_FAILURE';

export const FETCH_STOCK_LEVEL_REQUEST = 'FETCH_STOCK_LEVEL_REQUEST';
export const FETCH_STOCK_LEVEL_SUCCESS = 'FETCH_STOCK_LEVEL_SUCCESS';
export const FETCH_STOCK_LEVEL_FAILURE = 'FETCH_STOCK_LEVEL_FAILURE';

export const FETCH_STORE_STOCK_LEVEL_REQUEST = 'FETCH_STORE_STOCK_LEVEL_REQUEST';
export const FETCH_STORE_STOCK_LEVEL_SUCCESS = 'FETCH_STORE_STOCK_LEVEL_SUCCESS';
export const FETCH_STORE_STOCK_LEVEL_FAILURE = 'FETCH_STORE_STOCK_LEVEL_FAILURE';

export const FETCH_LOW_STOCK_PRODUCTS_REQUEST ="FETCH_LOW_STOCK_PRODUCTS_REQUEST";
export const FETCH_LOW_STOCK_PRODUCTS_SUCCESS ="FETCH_LOW_STOCK_PRODUCTS_SUCCESS";
export const FETCH_LOW_STOCK_PRODUCTS_FAILURE ="FETCH_LOW_STOCK_PRODUCTS_FAILURE"

export const FETCH_GROUP_STOCK_LEVEL_REQUEST = 'FETCH_GROUP_STOCK_LEVEL_REQUEST';
export const FETCH_GROUP_STOCK_LEVEL_SUCCESS = 'FETCH_GROUP_STOCK_LEVEL_SUCCESS';
export const FETCH_GROUP_STOCK_LEVEL_FAILURE = 'FETCH_GROUP_STOCK_LEVEL_FAILURE';

export const FETCH_STOCK_TRX_HISTORY_REQUEST = 'FETCH_STOCK_TRX_HISTORY_REQUEST';
export const FETCH_STOCK_TRX_HISTORY_SUCCESS = 'FETCH_STOCK_TRX_HISTORY_SUCCESS';
export const FETCH_STOCK_TRX_HISTORY_FAILURE = 'FETCH_STOCK_TRX_HISTORY_FAILURE';

export const FETCH_STOCK_REQ_HISTORY_REQUEST = 'FETCH_STOCK_REQ_HISTORY_REQUEST';
export const FETCH_STOCK_REQ_HISTORY_SUCCESS = 'FETCH_STOCK_REQ_HISTORY_SUCCESS';
export const FETCH_STOCK_REQ_HISTORY_FAILURE = 'FETCH_STOCK_REQ_HISTORY_FAILURE';

export const FETCH_STOCK_MOVEMENT_REQUEST = 'FETCH_STOCK_MOVEMENT_REQUEST';
export const FETCH_STOCK_MOVEMENT_SUCCESS = 'FETCH_STOCK_MOVEMENT_SUCCESS';
export const FETCH_STOCK_MOVEMENT_FAILURE = 'FETCH_STOCK_MOVEMENT_FAILURE';

export const FETCH_STOCK_MOVEMENT_DETAIL_REQUEST = 'FETCH_STOCK_MOVEMENT_DETAIL_REQUEST';
export const FETCH_STOCK_MOVEMENT_DETAIL_SUCCESS = 'FETCH_STOCK_MOVEMENT_DETAIL_SUCCESS';
export const FETCH_STOCK_MOVEMENT_DETAIL_FAILURE = 'FETCH_STOCK_MOVEMENT_DETAIL_FAILURE';

export const FETCH_STOCK_REQUEST_ANALYSIS_REQUEST = 'FETCH_STOCK_REQUEST_ANALYSIS_REQUEST';
export const FETCH_STOCK_REQUEST_ANALYSIS_SUCCESS = 'FETCH_STOCK_REQUEST_ANALYSIS_SUCCESS';
export const FETCH_STOCK_REQUEST_ANALYSIS_FAILURE = 'FETCH_STOCK_REQUEST_ANALYSIS_FAILURE';

export const FETCH_UNFULLFILLED_STOCK_REQUESTS_REQUEST = 'FETCH_UNFULLFILLED_STOCK_REQUESTS_REQUEST';
export const FETCH_UNFULLFILLED_STOCK_REQUESTS_SUCCESS = 'FETCH_UNFULLFILLED_STOCK_REQUESTS_SUCCESS';
export const FETCH_UNFULLFILLED_STOCK_REQUESTS_FAILURE = 'FETCH_UNFULLFILLED_STOCK_REQUESTS_FAILURE';

export const FETCH_PRODUCT_AGEING_REPORT_REQUEST = 'FETCH_PRODUCT_AGEING_REPORT_REQUEST';
export const FETCH_PRODUCT_AGEING_REPORT_SUCCESS = 'FETCH_PRODUCT_AGEING_REPORT_SUCCESS';
export const FETCH_PRODUCT_AGEING_REPORT_FAILURE = 'FETCH_PRODUCT_AGEING_REPORT_FAILURE';

export const FETCH_PROFIT_MARGIN_REQUEST = 'FETCH_PROFIT_MARGIN_REQUEST';
export const FETCH_PROFIT_MARGIN_SUCCESS = 'FETCH_PROFIT_MARGIN_SUCCESS';
export const FETCH_PROFIT_MARGIN_FAILURE = 'FETCH_PROFIT_MARGIN_FAILURE';

export const FETCH_VENDOR_TAX_INPUT_REQUEST = 'FETCH_VENDOR_TAX_INPUT_REQUEST';
export const FETCH_VENDOR_TAX_INPUT_SUCCESS = 'FETCH_VENDOR_TAX_INPUT_SUCCESS';
export const FETCH_VENDOR_TAX_INPUT_FAILURE = 'FETCH_VENDOR_TAX_INPUT_FAILURE';

export const FETCH_PRODUCTS_IN_PO_REQUEST = 'FETCH_PRODUCTS_IN_PO_REQUEST';
export const FETCH_PRODUCTS_IN_PO_SUCCESS = 'FETCH_PRODUCTS_IN_PO_SUCCESS';
export const FETCH_PRODUCTS_IN_PO_FAILURE = 'FETCH_PRODUCTS_IN_PO_FAILURE';

export const FETCH_CUSTOMER_CREDIT_SALE_REQUEST = 'FETCH_CUSTOMER_CREDIT_SALE_REQUEST';
export const FETCH_CUSTOMER_CREDIT_SALE_SUCCESS = 'FETCH_CUSTOMER_CREDIT_SALE_SUCCESS';
export const FETCH_CUSTOMER_CREDIT_SALE_FAILURE = 'FETCH_CUSTOMER_CREDIT_SALE_FAILURE';

export const FETCH_CREDIT_SETTLEMENT_REQUEST = 'FETCH_CREDIT_SETTLEMENT_REQUEST';
export const FETCH_CREDIT_SETTLEMENT_SUCCESS = 'FETCH_CREDIT_SETTLEMENT_SUCCESS';
export const FETCH_CREDIT_SETTLEMENT_FAILURE = 'FETCH_CREDIT_SETTLEMENT_FAILURE';

export const FETCH_CN_HISTORY_REQUEST = 'FETCH_CN_HISTORY_REQUEST';
export const FETCH_CN_HISTORY_SUCCESS = 'FETCH_CN_HISTORY_SUCCESS';
export const FETCH_CN_HISTORY_FAILURE = 'FETCH_CN_HISTORY_FAILURE';

export const FETCH_MESSAGE_HISTORY_REQUEST = 'FETCH_MESSAGE_HISTORY_REQUEST';
export const FETCH_MESSAGE_HISTORY_SUCCESS = 'FETCH_MESSAGE_HISTORY_SUCCESS';
export const FETCH_MESSAGE_HISTORY_FAILURE = 'FETCH_MESSAGE_HISTORY_FAILURE';

export const FETCH_INACTIVE_CUSTOMERS_REQUEST = 'FETCH_INACTIVE_CUSTOMERS_REQUEST';
export const FETCH_INACTIVE_CUSTOMERS_SUCCESS = 'FETCH_INACTIVE_CUSTOMERS_SUCCESS';
export const FETCH_INACTIVE_CUSTOMERS_FAILURE = 'FETCH_INACTIVE_CUSTOMERS_FAILURE';

export const FETCH_ATTENDANCE_REPORT_REQUEST = 'FETCH_ATTENDANCE_REPORT_REQUEST';
export const FETCH_ATTENDANCE_REPORT_SUCCESS = 'FETCH_ATTENDANCE_REPORT_SUCCESS';
export const FETCH_ATTENDANCE_REPORT_FAILURE = 'FETCH_ATTENDANCE_REPORT_FAILURE';

export const FETCH_INFO_DAYS_REPORT_REQUEST = 'FETCH_INFO_DAYS_REPORT_REQUEST';
export const FETCH_INFO_DAYS_REPORT_SUCCESS = 'FETCH_INFO_DAYS_REPORT_SUCCESS';
export const FETCH_INFO_DAYS_REPORT_FAILURE = 'FETCH_INFO_DAYS_REPORT_FAILURE';

export const FETCH_CASHIER_SUMMARY_REPORT_REQUEST = 'FETCH_CASHIER_SUMMARY_REPORT_REQUEST';
export const FETCH_CASHIER_SUMMARY_REPORT_SUCCESS = 'FETCH_CASHIER_SUMMARY_REPORT_SUCCESS';
export const FETCH_CASHIER_SUMMARY_REPORT_FAILURE = 'FETCH_CASHIER_SUMMARY_REPORT_FAILURE';

export const FETCH_MPH_REQUEST = 'FETCH_MPH_REQUEST';
export const FETCH_MPH_SUCCESS = 'FETCH_MPH_SUCCESS';
export const FETCH_MPH_FAILURE = 'FETCH_MPH_FAILURE';

export const FETCH_MEMBERSHIP_TRACKER_REQUEST = 'FETCH_MEMBERSHIP_TRACKER_REQUEST';
export const FETCH_MEMBERSHIP_TRACKER_SUCCESS = 'FETCH_MEMBERSHIP_TRACKER_SUCCESS';
export const FETCH_MEMBERSHIP_TRACKER_FAILURE = 'FETCH_MEMBERSHIP_TRACKER_FAILURE';

export const FETCH_MEMBERSHIP_PURCHASE_DETAIL_REQUEST = 'FETCH_MEMBERSHIP_PURCHASE_DETAIL_REQUEST';
export const FETCH_MEMBERSHIP_PURCHASE_DETAIL_SUCCESS = 'FETCH_MEMBERSHIP_PURCHASE_DETAIL_SUCCESS';
export const FETCH_MEMBERSHIP_PURCHASE_DETAIL_FAILURE = 'FETCH_MEMBERSHIP_PURCHASE_DETAIL_FAILURE';

export const CREATE_SERVICE_REQUEST = 'FETCH_CREATE_SERVICE_REQUEST';
export const CREATE_SERVICE_SUCCESS = 'FETCH_CREATE_SERVICE_SUCCESS';
export const CREATE_SERVICE_FAILURE = 'FETCH_CREATE_SERVICE_FAILURE';

export const UPDATE_SERVICE_REQUEST = 'UPDATE_SERVICE_REQUEST';
export const UPDATE_SERVICE_SUCCESS = 'UPDATE_SERVICE_SUCCESS';
export const UPDATE_SERVICE_FAILURE = 'UPDATE_SERVICE_FAILURE';

export const DELETE_SERVICE_REQUEST = 'DELETE_SERVICE_REQUEST';
export const DELETE_SERVICE_SUCCESS = 'DELETE_SERVICE_SUCCESS';
export const DELETE_SERVICE_FAILURE = 'DELETE_SERVICE_FAILURE';

export const FETCH_COLORS_REQUEST = 'FETCH_COLORS_REQUEST';
export const FETCH_COLORS_SUCCESS = 'FETCH_COLORS_SUCCESS';
export const FETCH_COLORS_FAILURE = 'FETCH_COLORS_FAILURE';

export const FETCH_SIZE_REQUEST = 'FETCH_SIZE_REQUEST';
export const FETCH_SIZE_SUCCESS = 'FETCH_SIZE_SUCCESS';
export const FETCH_SIZE_FAILURE = 'FETCH_SIZE_FAILURE';

export const FETCH_CHARGES_REQUEST = 'FETCH_CHARGES_REQUEST';
export const FETCH_CHARGES_SUCCESS = 'FETCH_CHARGES_SUCCESS';
export const FETCH_CHARGES_FAILURE = 'FETCH_CHARGES_FAILURE';

export const FETCH_BATCHES_REQUEST = 'FETCH_BATCHES_REQUEST';
export const FETCH_BATCHES_SUCCESS = 'FETCH_BATCHES_SUCCESS';
export const FETCH_BATCHES_FAILURE = 'FETCH_BATCHES_FAILURE';

export const FETCH_LOCALITY_ORDERS_REQUEST = 'FETCH_LOCALITY_ORDERS_REQUEST';
export const FETCH_LOCALITY_ORDERS_SUCCESS = 'FETCH_LOCALITY_ORDERS_SUCCESS';
export const FETCH_LOCALITY_ORDERS_FAILURE = 'FETCH_LOCALITY_ORDERS_FAILURE';

export const STORE_LOCALITY_MAPPING_REQUEST = 'STORE_LOCALITY_MAPPING_REQUEST';
export const STORE_LOCALITY_MAPPING_SUCCESS = 'STORE_LOCALITY_MAPPING_SUCCESS';
export const STORE_LOCALITY_MAPPING_FAILURE = 'STORE_LOCALITY_MAPPING_FAILURE';

export const GET_CHAIN_ECOM_STORES_REQUEST = 'GET_CHAIN_ECOM_STORES_REQUEST';
export const GET_CHAIN_ECOM_STORES_SUCCESS = 'GET_CHAIN_ECOM_STORES_SUCCESS';
export const GET_CHAIN_ECOM_STORES_FAILURE = 'GET_CHAIN_ECOM_STORES_FAILURE';

export const DISPATCH_ORDER_REQUEST = 'DISPATCH_ORDER_REQUEST';
export const DISPATCH_ORDER_SUCCESS = 'DISPATCH_ORDER_SUCCESS';
export const DISPATCH_ORDER_FAILURE = 'DISPATCH_ORDER_FAILURE';
export const CLEAR_DISPATCH_ORDER_STATUS = 'CLEAR_DISPATCH_ORDER_STATUS';

export const FETCH_DO_SUMMARY_REQUEST = 'FETCH_DO_SUMMARY_REQUEST';
export const FETCH_DO_SUMMARY_SUCCESS = 'FETCH_DO_SUMMARY_SUCCESS';
export const FETCH_DO_SUMMARY_FAILURE = 'FETCH_DO_SUMMARY_FAILURE';

export const FETCH_DO_DETAIL_REQUEST = 'FETCH_DO_DETAIL_REQUEST';
export const FETCH_DO_DETAIL_SUCCESS = 'FETCH_DO_DETAIL_SUCCESS';
export const FETCH_DO_DETAIL_FAILURE = 'FETCH_DO_DETAIL_FAILURE';

export const SET_EDIT_SERVICE = 'SET_EDIT_SERVICE';

export const FETCH_POINT_LEDGER= 'FETCH_POINT_LEDGER';
export const FETCH_REPORT_CUSTOMER_SALES= 'FETCH_REPORT_CUSTOMER_SALES';

export const FETCH_REPORT_TRACKER_LIST_REQUEST = 'FETCH_REPORT_TRACKER_LIST_REQUEST';
export const FETCH_REPORT_TRACKER_LIST_SUCCESS = 'FETCH_REPORT_TRACKER_LIST_SUCCESS';
export const FETCH_REPORT_TRACKER_LIST_FAILURE = 'FETCH_REPORT_TRACKER_LIST_FAILURE';


//USER/CUSTOMER

export const FETCH_USERS_REQUEST = 'FETCH_USERS_REQUEST';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_USERS_FAILURE = 'FETCH_USERS_FAILURE';

export const FETCH_ROLES_MENU='FETCH_ROLES_MENU';
export const ADD_USER='ADD_USER';
export const USERNAME_AVAILABILITY='USERNAME_AVAILABILITY';
export const CHANGE_PASSWORD_REQUEST='CHANGE_PASSWORD_REQUEST';
export const CHANGE_PASSWORD_SUCCESS='CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILURE='CHANGE_PASSWORD_FAILURE';
export const SEND_NEWMOBILE_OTP_REQUEST='SEND_NEWMOBILE_OTP_REQUEST';
export const SEND_NEWMOBILE_OTP_SUCCESS='SEND_NEWMOBILE_OTP_SUCCESS';
export const SEND_NEWMOBILE_OTP_FAILURE='SEND_NEWMOBILE_OTP_FAILURE';

export const VERIFY_NEWMOBILE_OTP_REQUEST='VERIFY_NEWMOBILE_OTP_REQUEST';
export const VERIFY_NEWMOBILE_OTP_SUCCESS='VERIFY_NEWMOBILE_OTP_SUCCESS';
export const VERIFY_NEWMOBILE_OTP_FAILURE='VERIFY_NEWMOBILE_OTP_FAILURE';
export const UPDATE_PERMISSIONS="UPDATE_PERMISSIONS";
export const EDIT_USERS="EDIT_USERS";
export const CREATE_WAREHOUSE="CREATE_WAREHOUSE";
export const GET_WAREHOUSE_REQUEST="GET_WAREHOUSE_REQUEST";
export const GET_WAREHOUSE_SUCCESS="GET_WAREHOUSE_SUCCESS";
export const GET_WAREHOUSE_FAILURE="GET_WAREHOUSE_FAILURE";
export const UPDATE_WAREHOUSE="UPDATE_WAREHOUSE";
export const DELETE_WAREHOUSE="DELETE_WAREHOUSE";

export const ADD_ATTRIBUTE="ADD_ATTRIBUTE";
export const FETCH_ATTRIBUTES_REQUEST="FETCH_ATTRIBUTES_REQUEST";
export const FETCH_ATTRIBUTES_SUCCESS="FETCH_ATTRIBUTES_SUCCESS";
export const FETCH_ATTRIBUTES_FAILURE="FETCH_ATTRIBUTES_FAILURE";
export const UPDATE_ATTRIBUTE="UPDATE_ATTRIBUTE";
export const DELETE_ATTRIBUTE="DELETE_ATTRIBUTE";
export const ALL_ATTRIBUTES="ALL_ATTRIBUTES";

export const FETCH_SYSTEMATTRIBUTES_FAILURE='FETCH_SYSTEMATTRIBUTES_FAILURE';
export const FETCH_SYSTEMATTRIBUTES_REQUEST='FETCH_SYSTEMATTRIBUTES_REQUEST';
export const FETCH_SYSTEMATTRIBUTES_SUCCESS='FETCH_SYSTEMATTRIBUTES_SUCCESS';
export const UPDATE_SYSTEMATTRIBUTE='UPDATE_SYSTEMATTRIBUTE';

export const GET_TAX_PRODS="GET_TAX_PRODS";
export const GET_CHARGE_PRODS="GET_CHARGE_PRODS"
export const ADDSUBCAT_REQUEST="ADDSUBCAT_REQUEST";
export const ADDSUBCAT_SUCCESS="ADDSUBCAT_SUCCESS";
export const ADDSUBCAT_FAILURE="ADDSUBCAT_FAILURE";
export const EDITSUBCAT_REQUEST="EDITSUBCAT_REQUEST";
export const EDITSUBCAT_SUCCESS="EDITSUBCAT_SUCCESS";
export const EDITSUBCAT_FAILURE="EDITSUBCAT_FAILURE";
export const FETCH_STOREPRICE_REQUEST="FETCH_STOREPRICE_REQUEST";
export const FETCH_STOREPRICE_SUCCESS="FETCH_STOREPRICE_SUCCESS";
export const FETCH_STOREPRICE_FAILURE="FETCH_STOREPRICE_FAILURE";
export const ADD_BULKBRANDS="ADD_BULKBRANDS";
export const EDIT_BULKBRANDS="EDIT_BULKBRANDS";
export const ADD_BULKCATEGORY="ADD_BULKCATEGORY";
export const EDIT_BULKCATEGORY='EDIT_BULKCATEGORY';
export const ADD_BULKSUBCATEGORY="ADD_BULKSUBCATEGORY";
export const EDIT_BULKSUBCATEGORY='EDIT_BULKSUBCATEGORY';



export const FETCH_ESTOREPRODS_REQUEST='FETCH_ESTOREPRODS_REQUEST'
export const FETCH_ESTOREPRODS_SUCCESS='FETCH_ESTOREPRODS_SUCCESS'
export const FETCH_ESTOREPRODS_FAILURE='FETCH_ESTOREPRODS_FAILURE'
export const FETCH_BANNERS_REQUEST="FETCH_BANNERS_REQUEST";
export const FETCH_BANNERS_SUCCESS="FETCH_BANNERS_SUCCESS";
export const FETCH_BANNERS_FAILURE="FETCH_BANNERS_FAILURE";
export const DELETE_BANNER_REQUEST="DELETE_BANNER_REQUEST";
export const DELETE_BANNER_SUCCESS="DELETE_BANNER_SUCCESS";
export const DELETE_BANNER_FAILURE="DELETE_BANNER_FAILURE";
export const UPDATE_BANNER_REQUEST='UPDATE_BANNER_REQUEST';
export const UPDATE_BANNER_SUCCESS='UPDATE_BANNER_SUCCESS';
export const UPDATE_BANNER_FAILURE='UPDATE_BANNER_FAILURE';
export const CREATE_BANNER_REQUEST='CREATE_BANNER_REQUEST';
export const CREATE_BANNER_SUCCESS='CREATE_BANNER_SUCCESS';
export const CREATE_BANNER_FAILURE='CREATE_BANNER_FAILURE';



export const FETCH_ESTORE_RESERV_SET_REQUEST="FETCH_ESTORE_RESERV_SET_REQUEST";
export const FETCH_ESTORE_RESERV_SET_SUCCESS="FETCH_ESTORE_RESERV_SET_SUCCESS";
export const FETCH_ESTORE_RESERV_SET_FAILURE="FETCH_ESTORE_RESERV_SET_FAILURE";

export const FETCH_ESTORE_CHAINSETTINGS_REQUEST="FETCH_ESTORE_CHAINSETTINGS_REQUEST";
export const FETCH_ESTORE_CHAINSETTINGS_SUCCESS="FETCH_ESTORE_CHAINSETTINGS_SUCCESS";
export const FETCH_ESTORE_CHAINSETTINGS_FAILURE="FETCH_ESTORE_CHAINSETTINGS_FAILURE";

export const FETCH_ONLINE_CREDIT_INFO_REQUEST="FETCH_ONLINE_CREDIT_INFO_REQUEST";
export const FETCH_ONLINE_CREDIT_INFO_SUCCESS="FETCH_ONLINE_CREDIT_INFO_SUCCESS";
export const FETCH_ONLINE_CREDIT_INFO_FAILURE="FETCH_ONLINE_CREDIT_INFO_FAILURE";
export const FETCH_ONLINE_CREDIT_RATE="FETCH_ONLINE_CREDIT_RATE";




export const FETCH_CREDIT_PACKAGE_INFO_REQUEST="FETCH_CREDIT_PACKAGE_INFO_REQUEST";
export const FETCH_CREDIT_PACKAGE_INFO_SUCCESS="FETCH_CREDIT_PACKAGE_INFO_SUCCESS";
export const FETCH_CREDIT_PACKAGE_INFO_FAILURE="FETCH_CREDIT_PACKAGE_INFO_FAILURE";

export const ESTORE_EDITCHAINSETTINGS='ESTORE_EDITCHAINSETTINGS';

export const FETCH_ESTORE_STORESETTINGS_REQUEST="FETCH_ESTORE_STORESETTINGS_REQUEST";
export const FETCH_ESTORE_STORESETTINGS_SUCCESS="FETCH_ESTORE_STORESETTINGS_SUCCESS";
export const FETCH_ESTORE_STORESETTINGS_FAILURE="FETCH_ESTORE_STORESETTINGS_FAILURE";

export const ESTORE_EDITSTORESETTINGS='ESTORE_EDITSTORESETTINGS';
export const CHAIN_ENQUIRY_MAIL='CHAIN_ENQUIRY_MAIL';

export const CREATE_MODIFIER = 'CREATE_MODIFIER';

export const GET_MODIFIERS_REQUEST= 'GET_MODIFIERS_REQUEST';
export const GET_MODIFIERS_SUCCESS= 'GET_MODIFIERS_SUCCESS';
export const GET_MODIFIERS_FAILURE= 'GET_MODIFIERS_FAILURE';
export const ADD_SINGLE_PRODUCT='ADD_SINGLE_PRODUCT';
export const EDIT_SINGLE_PRODUCT='EDIT_SINGLE_PRODUCT';

export const GET_STOCKCHAINPRODS_REQUEST='GET_STOCKCHAINPRODS_REQUEST';
export const GET_STOCKCHAINPRODS_SUCCESS='GET_STOCKCHAINPRODS_SUCCESS';
export const GET_STOCKCHAINPRODS_FAILURE='GET_STOCKCHAINPRODS_FAILURE';

export const GET_EDITPRODUCT_REQUEST='GET_EDITPRODUCT_REQUEST';
export const GET_EDITPRODUCT_SUCCESS='GET_EDITPRODUCT_SUCCESS';
export const GET_EDITPRODUCT_FAILURE='GET_EDITPRODUCT_FAILURE';
export const CLEAR_EDITPRODUCT='CLEAR_EDITPRODUCT';
export const DEL_PROD='DEL_PROD';
export const DEL_BATCH='DEL_BATCH';
export const MAP_BULK='MAP_BULK';

export const EDIT_CATEGORY_REQUEST='EDIT_CATEGORY_REQUEST';
export const EDIT_CATEGORY_SUCCESS='EDIT_CATEGORY_SUCCESS';
export const EDIT_CATEGORY_FAILURE='EDIT_CATEGORY_FAILURE';

export const CREATE_CATEGORY_REQUEST='CREATE_CATEGORY_REQUEST';
export const CREATE_CATEGORY_SUCCESS='CREATE_CATEGORY_SUCCESS';
export const CREATE_CATEGORY_FAILURE='CREATE_CATEGORY_FAILURE';
export const ADDBRAND_REQUEST='ADDBRAND_REQUEST';
export const ADDBRAND_SUCCESS='ADDBRAND_SUCCESS';
export const ADDBRAND_FAILURE='ADDBRAND_FAILURE';
export const EDITBRAND_REQUEST='EDITBRAND_REQUEST';
export const EDITBRAND_SUCCESS='EDITBRAND_SUCCESS';
export const EDITBRAND_FAILURE='EDITBRAND_FAILURE';
export const ADDTAX_REQUEST='ADDTAX_REQUEST';
export const ADDTAX_SUCCESS='ADDTAX_SUCCESS';
export const ADDTAX_FAILURE='ADDTAX_FAILURE';
export const EDITTAX_REQUEST='EDITTAX_REQUEST';
export const EDITTAX_SUCCESS='EDITTAX_SUCCESS';
export const EDITTAX_FAILURE='EDITTAX_FAILURE';
export const ADDCHARGE_REQUEST='ADDCHARGE_REQUEST';
export const ADDCHARGE_SUCCESS='ADDCHARGE_SUCCESS';
export const ADDCHARGE_FAILURE='ADDCHARGE_FAILURE';
export const EDITCHARGE_REQUEST='EDITCHARGE_REQUEST';
export const EDITCHARGE_SUCCESS='EDITCHARGE_SUCCESS';
export const EDITCHARGE_FAILURE='EDITCHARGE_FAILURE';

export const ADDCOMBO_REQUEST='ADDCOMBO_REQUEST';
export const ADDCOMBO_SUCCESS='ADDCOMBO_SUCCESS';
export const ADDCOMBO_FAILURE='ADDCOMBO_FAILURE';

export const EDITCOMBO_REQUEST='EDITCOMBO_REQUEST';
export const EDITCOMBO_SUCCESS='EDITCOMBO_SUCCESS';
export const EDITCOMBO_FAILURE='EDITCOMBO_FAILURE';

export const UPDATEPASSWORD_REQUEST='UPDATEPASSWORD_REQUEST';
export const UPDATEPASSWORD_SUCCESS='UPDATEPASSWORD_SUCCESS';
export const UPDATEPASSWORD_FAILURE='UPDATEPASSWORD_FAILURE';





export const EDITSINGLEPRODESTORE_REQUEST='EDITSINGLEPRODESTORE_REQUEST';
export const EDITSINGLEPRODESTORE_SUCCESS='EDITSINGLEPRODESTORE_SUCCESS';
export const EDITSINGLEPRODESTORE_FAILURE='EDITSINGLEPRODESTORE_FAILURE';

export const UPDATEBULKPRODESTORE_REQUEST='UPDATEBULKPRODESTORE_REQUEST';
export const UPDATEBULKPRODESTORE_SUCCESS='UPDATEBULKPRODESTORE_SUCCESS';
export const UPDATEBULKPRODESTORE_FAILURE='UPDATEBULKPRODESTORE_FAILURE';

export const ESTORE_BULKCATALOG_REQUEST='ESTORE_BULKCATALOG_REQUEST';
export const ESTORE_BULKCATALOG_SUCCESS='ESTORE_BULKCATALOG_SUCCESS';
export const ESTORE_BULKCATALOG_FAILURE='ESTORE_BULKCATALOG_FAILURE';

export const INFO_LISCENCEPAY_REQUEST='INFO_LISCENCEPAY_REQUEST';
export const INFO_LISCENCEPAY_SUCCESS='INFO_LISCENCEPAY_SUCCESS';
export const INFO_LISCENCEPAY_FAILURE='INFO_LISCENCEPAY_FAILURE';

export const CHECK_LISCENCEPROMO_REQUEST='CHECK_LISCENCEPROMO_REQUEST';
export const CHECK_LISCENCEPROMO_SUCCESS='CHECK_LISCENCEPROMO_SUCCESS';
export const CHECK_LISCENCEPROMO_FAILURE='CHECK_LISCENCEPROMO_FAILURE';

export const RENEW_LISCENCE_REQUEST='RENEW_LISCENCE_REQUEST';
export const RENEW_LISCENCE_SUCCESS='RENEW_LISCENCE_SUCCESS';
export const RENEW_LISCENCE_FAILURE='RENEW_LISCENCE_FAILURE';

export const CHECK_USERNAME_REQUEST='CHECK_USERNAME_REQUEST';
export const CHECK_USERNAME_SUCCESS='CHECK_USERNAME_SUCCESS';
export const CHECK_USERNAME_FAILURE='CHECK_USERNAME_FAILURE';

export const CHECK_OTP_REQUEST='CHECK_OTP_REQUEST';
export const CHECK_OTP_SUCCESS='CHECK_OTP_SUCCESS';
export const CHECK_OTP_FAILURE='CHECK_OTP_FAILURE';

export const CHECK_PASSWORD='CHECK_PASSWORD';
export const CHECK_PASSWORD_REQUEST='CHECK_PASSWORD_REQUEST';
export const CHECK_PASSWORD_SUCCESS='CHECK_PASSWORD_SUCCESS';
export const CHECK_PASSWORD_FAILURE='CHECK_PASSWORD_FAILURE';

export const CHECK_SIGNUP_REQUEST = 'CHECK_SIGNUP_REQUEST';
export const CHECK_SIGNUP_SUCCESS = 'CHECK_SIGNUP_SUCCESS';
export const CHECK_SIGNUP_FAILURE = 'CHECK_SIGNUP_FAILURE';

export const CHECK_VENDORLIST_REQUEST = 'CHECK_VENDORLIST_REQUEST';
export const CHECK_VENDORLIST_SUCCESS = 'CHECK_VENDORLIST_SUCCESS';
export const CHECK_VENDORLIST_FAILURE = 'CHECK_VENDORLIST_FAILURE';

export const CHECK_VENDOR_MAPPING_REQUEST = 'CHECK_VENDOR_MAPPING_REQUEST';
export const CHECK_VENDOR_MAPPING_SUCCESS = 'CHECK_VENDOR_MAPPING_SUCCESS';
export const CHECK_VENDOR_MAPPING_FAILURE = 'CHECK_VENDOR_MAPPING_FAILURE';

export const CHECK_STORE_ACTIVATION_ON_DEVICE_REQUEST = 'CHECK_STORE_ACTIVATION_ON_DEVICE_REQUEST';
export const CHECK_STORE_ACTIVATION_ON_DEVICE_SUCCESS = 'CHECK_STORE_ACTIVATION_ON_DEVICE_SUCCESS';
export const CHECK_STORE_ACTIVATION_ON_DEVICE_FAILURE = 'CHECK_STORE_ACTIVATION_ON_DEVICE_FAILURE';

export const CHECK_EMPLOYEE_WISE_SALES_REQUEST = 'CHECK_EMPLOYEE_WISE_SALES_REQUEST';
export const CHECK_EMPLOYEE_WISE_SALES_SUCCESS = 'CHECK_EMPLOYEE_WISE_SALES_SUCCESS';
export const CHECK_EMPLOYEE_WISE_SALES_FAILURE = 'CHECK_EMPLOYEE_WISE_SALES_FAILURE';

export const CHECK_INDUSTRY_LIST_REQUEST = 'CHECK_INDUSTRY_LIST_REQUEST';
export const CHECK_INDUSTRY_LIST_SUCCESS = 'CHECK_INDUSTRY_LIST_SUCCESS';
export const CHECK_INDUSTRY_LIST_FAILURE = 'CHECK_INDUSTRY_LIST_FAILURE';

export const UPDATE_BASIC_INFO_REQUEST = 'UPDATE_BASIC_INFO_REQUEST';
export const UPDATE_BASIC_INFO_SUCCESS = 'UPDATE_BASIC_INFO_SUCCESS';
export const UPDATE_BASIC_INFO_FAILURE = 'UPDATE_BASIC_INFO_FAILURE';

export const FETCH_DISCOUNT_ORDER_REPORT_REQUEST = "FETCH_DISCOUNT_ORDER_REPORT_REQUEST";
export const FETCH_DISCOUNT_ORDER_REPORT_SUCCESS = "FETCH_DISCOUNT_ORDER_REPORT_SUCCESS";
export const FETCH_DISCOUNT_ORDER_REPORT_FAILURE = "FETCH_DISCOUNT_ORDER_REPORT_FAILURE";

export const FETCH_PRODUCT_WISE_SALES_REQUEST ="FETCH_PRODUCT_WISE_SALES_REQUEST";
export const FETCH_PRODUCT_WISE_SALES_SUCCESS = "FETCH_PRODUCT_WISE_SALES_SUCCESS";
export const FETCH_PRODUCT_WISE_SALES_FAILURE ="FETCH_PRODUCT_WISE_SALES_FAILURE";

export const FETCH_ORDER_WISE_PAYMENT_BREAKUP_REQUEST = "FETCH_ORDER_WISE_PAYMENT_BREAKUP_REQUEST";
export const FETCH_ORDER_WISE_PAYMENT_BREAKUP_SUCCESS = "FETCH_ORDER_WISE_PAYMENT_BREAKUP_SUCCESS";
export const FETCH_ORDER_WISE_PAYMENT_BREAKUP_FAILURE = "FETCH_ORDER_WISE_PAYMENT_BREAKUP_FAILURE";

export const FETCH_ONLINE_ORDER_REPORT_REQUEST ="FETCH_ONLINE_ORDER_REPORT_REQUEST";
export const FETCH_ONLINE_ORDER_REPORT_SUCCESS = "FETCH_ONLINE_ORDER_REPORT_SUCCESS";
export const FETCH_ONLINE_ORDER_REPORT_FAILURE ="FETCH_ONLINE_ORDER_REPORT_FAILURE";

export const FETCH_PRODUCT_IN_ONLINE_ORDER_REPORT_REQUEST ="FETCH_PRODUCT_IN_ONLINE_ORDER_REPORT_REQUEST";
export const FETCH_PRODUCT_IN_ONLINE_ORDER_REPORT_SUCCESS = "FETCH_PRODUCT_IN_ONLINE_ORDER_REPORT_SUCCESS";
export const FETCH_PRODUCT_IN_ONLINE_ORDER_REPORT_FAILURE ="FETCH_PRODUCT_IN_ONLINE_ORDER_REPORT_FAILURE";

export const FETCH_ONLINE_ORDER_DETAIL_REPORT_REQUEST ="FETCH_ONLINE_ORDER_DETAIL_REPORT_REQUEST";
export const FETCH_ONLINE_ORDER_DETAIL_REPORT_SUCCESS = "FETCH_ONLINE_ORDER_DETAIL_REPORT_SUCCESS";
export const FETCH_ONLINE_ORDER_DETAIL_REPORT_FAILURE ="FETCH_ONLINE_ORDER_DETAIL_REPORT_FAILURE";

export const FETCH_STORE_SALES_TRANSACTION_TRACKER_REQUEST="FETCH_STORE_SALES_TRANSACTION_TRACKER_REQUEST";
export const FETCH_STORE_SALES_TRANSACTION_TRACKER_SUCCESS="FETCH_STORE_SALES_TRANSACTION_TRACKER_SUCCESS";
export const FETCH_STORE_SALES_TRANSACTION_TRACKER_FAILURE="FETCH_STORE_SALES_TRANSACTION_TRACKER_FAILURE";

export const FETCH_ONLINE_ORDER_WISE_PAYMENT_BREAKUP_REQUEST="FETCH_ONLINE_ORDER_WISE_PAYMENT_BREAKUP_REQUEST";
export const FETCH_ONLINE_ORDER_WISE_PAYMENT_BREAKUP_SUCCESS="FETCH_ONLINE_ORDER_WISE_PAYMENT_BREAKUP_SUCCESS";
export const FETCH_ONLINE_ORDER_WISE_PAYMENT_BREAKUP_FAILURE="FETCH_ONLINE_ORDER_WISE_PAYMENT_BREAKUP_FAILURE";


export const FETCH_ORDER_PAYMENT_SUMMARY_WEB_REQUEST="FETCH_ORDER_PAYMENT_SUMMARY_WEB_REQUEST";
export const FETCH_ORDER_PAYMENT_SUMMARY_WEB_SUCCESS="FETCH_ORDER_PAYMENT_SUMMARY_WEB_SUCCESS";
export const FETCH_ORDER_PAYMENT_SUMMARY_WEB_FAILURE="FETCH_ORDER_PAYMENT_SUMMARY_WEB_FAILURE";

export const FETCH_HSNSAC_WISE_TAX_BREAKUP_REQUEST="FETCH_HSNSAC_WISE_TAX_BREAKUP_REQUEST";
export const FETCH_HSNSAC_WISE_TAX_BREAKUP_SUCCESS="FETCH_HSNSAC_WISE_TAX_BREAKUP_SUCCESS";
export const FETCH_HSNSAC_WISE_TAX_BREAKUP_FAILURE="FETCH_HSNSAC_WISE_TAX_BREAKUP_FAILURE";

export const FETCH_ORDER_COMBO_REPORT_REQUEST="FETCH_ORDER_COMBO_REPORT_REQUEST";
export const FETCH_ORDER_COMBO_REPORT_SUCCESS="FETCH_ORDER_COMBO_REPORT_SUCCESS";
export const FETCH_ORDER_COMBO_REPORT_FAILURE="FETCH_ORDER_COMBO_REPORT_FAILURE";

export const FETCH_SUB_CATEGORY_SALES_REQUEST="FETCH_SUB_CATEGORY_SALES_REQUEST";
export const FETCH_SUB_CATEGORY_SALES_SUCCESS="FETCH_SUB_CATEGORY_SALES_SUCCESS";
export const FETCH_SUB_CATEGORY_SALES_FAILURE="FETCH_SUB_CATEGORY_SALES_FAILURE";


export const FETCH_GET_STORE_HOURLY_SALES_REQUEST="FETCH_GET_STORE_HOURLY_SALES_REQUEST";
export const FETCH_GET_STORE_HOURLY_SALES_SUCCESS="FETCH_GET_STORE_HOURLY_SALES_SUCCESS";
export const FETCH_GET_STORE_HOURLY_SALES_FAILURE="FETCH_GET_STORE_HOURLY_SALES_FAILURE";

export const GET_STORE_CATALOGUE_LIST_REQUEST="GET_STORE_CATALOGUE_LIST_REQUEST";
export const GET_STORE_CATALOGUE_LIST_SUCCESS="GET_STORE_CATALOGUE_LIST_SUCCESS";
export const GET_STORE_CATALOGUE_LIST_FAILURE="GET_STORE_CATALOGUE_LIST_FAILURE"

export const GET_PRODUCTS_BYCHAIN_EXCEL = "GET_PRODUCTS_BYCHAIN_EXCEL" ;

export const GET_BATCHES_WEB_REQUEST="GET_BATCHES_WEB_REQUEST";
export const GET_BATCHES_WEB_SUCCESS="GET_BATCHES_WEB_SUCCESS";
export const GET_BATCHES_WEB_FAILURE="GET_BATCHES_WEB_FAILURE"


//  Expense order Reports

export const FETCH_EXPENSE_ORDERS_REQUEST = "FETCH_EXPENSE_ORDERS_REQUEST";
export const FETCH_EXPENSE_ORDERS_SUCCESS = "FETCH_EXPENSE_ORDERS_SUCCESS";
export const FETCH_EXPENSE_ORDERS_FAILURE = "FETCH_EXPENSE_ORDERS_FAILURE";

export const FETCH_EXPENSE_ORDERS_DETAILS_REQUEST = "FETCH_EXPENSE_ORDERS_DETAILS_REQUEST";
export const FETCH_EXPENSE_ORDERS_DETAILS_SUCCESS = "FETCH_EXPENSE_ORDERS_DETAILS_SUCCESS";
export const FETCH_EXPENSE_ORDERS_DETAILS_FAILURE = "FETCH_EXPENSE_ORDERS_DETAILS_FAILURE";


export const FETCH_PRODUCT_EXPENSE_ORDER_INFO_REQUEST = "FETCH_PRODUCT_EXPENSE_ORDER_INFO_REQUEST";
export const FETCH_PRODUCT_EXPENSE_ORDER_INFO_SUCCESS = "FETCH_PRODUCT_EXPENSE_ORDER_INFO_SUCCESS";
export const FETCH_PRODUCT_EXPENSE_ORDER_INFO_FAILURE = "FETCH_PRODUCT_EXPENSE_ORDER_INFO_FAILURE";

export const FETCH_EXPENSE_CATEGORY_REQUEST = "FETCH_EXPENSE_CATEGORY_REQUEST";
export const FETCH_EXPENSE_CATEGORY_SUCCESS = "FETCH_EXPENSE_CATEGORY_SUCCESS";
export const FETCH_EXPENSE_CATEGORY_FAILURE = "FETCH_EXPENSE_CATEGORY_FAILURE";

export const FETCH_ALTER_EXPENSE_CATEGORY_REQUEST = "FETCH_ALTER_EXPENSE_CATEGORY_REQUEST";
export const FETCH_ALTER_EXPENSE_CATEGORY_SUCCESS = "FETCH_ALTER_EXPENSE_CATEGORY_SUCCESS";
export const FETCH_ALTER_EXPENSE_CATEGORY_FAILURE = "FETCH_ALTER_EXPENSE_CATEGORY_FAILURE";
//muncipal corp reposts
export const ALL_CHARGES_REPORTS_REQUEST = "ALL_CHARGES_REPORTS_REQUEST"
export const ALL_CHARGES_REPORTS_SUCCESS = "ALL_CHARGES_REPORTS_SUCCESS"
export const ALL_CHARGES_REPORTS_FAILURE = "ALL_CHARGES_REPORTS_FAILURE"

export const CUMULATIVE_COLLECTION_REPORTS_REQUEST = "CUMULATIVE_COLLECTION_REPORTS_REQUEST"
export const CUMULATIVE_COLLECTION_REPORTS_SUCCESS = "CUMULATIVE_COLLECTION_REPORTS_SUCCESS"
export const CUMULATIVE_COLLECTION_REPORTS_FAILURE = "CUMULATIVE_COLLECTION_REPORTS_FAILURE"

export const GET_WEB_MUNCIPAL_DETAILS_REQUEST = "GET_WEB_MUNCIPAL_DETAILS_REQUEST"
export const GET_WEB_MUNCIPAL_DETAILS_SUCCESS = "GET_WEB_MUNCIPAL_DETAILS_SUCCESS"
export const GET_WEB_MUNCIPAL_DETAILS_FAILURE = "GET_WEB_MUNCIPAL_DETAILS_FAILURE"
//Appointments
export const GET_APPOINTMENTS_DATA_REQUEST = "GET_APPOINTMENTS_DATA_REQUEST"
export const GET_APPOINTMENTS_DATA_SUCCESS = "GET_APPOINTMENTS_DATA_SUCCESS"
export const GET_APPOINTMENTS_DATA_FAILURE = "GET_APPOINTMENTS_DATA_FAILURE"

export const GET_USERS_DATA_REQUEST = "GET_USERS_DATA_REQUEST"
export const GET_USERS_DATA_SUCCESS = "GET_USERS_DATA_SUCCESS"
export const GET_USERS_DATA_FAILURE = "GET_USERS_DATA_FAILURE"


export const PRODUCT_DISCOUNT_REPORT_REQUEST='PRODUCT_DISCOUNT_REPORT_REQUEST';
export const PRODUCT_DISCOUNT_REPORT_SUCCESS='PRODUCT_DISCOUNT_REPORT_SUCCESS';
export const PRODUCT_DISCOUNT_REPORT_FAILURE='PRODUCT_DISCOUNT_REPORT_FAILURE';




export const FETCH_PRODUCTS_DETAILS_IN_PO_REQUEST="FETCH_PRODUCTS_DETAILS_IN_PO_REQUEST"
export const FETCH_PRODUCTS_DETAILS_IN_PO_SUCCESS="FETCH_PRODUCTS_DETAILS_IN_PO_SUCCESS"
export const FETCH_PRODUCTS_DETAILS_IN_PO_FAILURE="FETCH_PRODUCTS_DETAILS_IN_PO_FAILURE"

export const FETCH_PRODUCT_TRANSACTION_TRACKER_REQUEST = "FETCH_PRODUCT_TRANSACTION_TRACKER_REQUEST"
export const FETCH_PRODUCT_TRANSACTION_TRACKER_SUCCESS = 'FETCH_PRODUCT_TRANSACTION_TRACKER_SUCCESS'
export const FETCH_PRODUCT_TRANSACTION_TRACKER_FAILURE = 'FETCH_PRODUCT_TRANSACTION_TRACKER_FAILURE'

export const GET_CITY_DATA = "GET_CITY_DATA"

export const GET_TRANSACTION_DETAILS_REQUEST = "GET_TRANSACTION_DETAILS_REQUEST"
export const GET_TRANSACTION_DETAILS_SUCCESS = "GET_TRANSACTION_DETAILS_SUCCESS"
export const GET_TRANSACTION_DETAILS_FAILURE = "GET_TRANSACTION_DETAILS_FAILURE"

export const GET_CHAINLOG_DATA_REQUEST = "GET_CHAINLOG_DATA_REQUEST"
export const GET_CHAINLOG_DATA_SUCCESS = "GET_CHAINLOG_DATA_SUCCESS"
export const GET_CHAINLOG_DATA_FAILURE = "GET_CHAINLOG_DATA_FAILUR"

export const GET_SYNC_TYPE_REQUEST = "GET_SYNC_TYPE_REQUEST"
export const GET_SYNC_TYPE_SUCCESS = "GET_SYNC_TYPE_SUCCESS"
export const GET_SYNC_TYPE_FAILURE = "GET_SYNC_TYPE_FAILURE"

export const FETCH_REQUISTION_DETAILS_REQUEST = "FETCH_REQUISTION_DETAILS_REQUEST"
export const FETCH_REQUISTION_DETAILS_SUCCESS = "FETCH_REQUISTION_DETAILS_SUCCESS"
export const FETCH_REQUISTION_DETAILS_FAILURE = "FETCH_REQUISTION_DETAILS_FAILURE"

export const SET_PREFFERNCE = "SET_PREFFERNCE"

//Billing
export const GET_ALL_PRODUCT = "GET_ALL_PRODUCT";
export const GET_NUMBER_CART = "GET_NUMBER_CART";
export const ADD_CART = "ADD_CART";
export const UPDATE_CART = "UPDATE_CART";
export const INCREASE_QUANTITY = "INCREASE_QUANTITY";
export const DECREASE_QUANTITY = "DECREASE_QUANTITY";
export const DELETE_CART = "DELETE_CART";
export const COMBOS_DATA = "COMBOS_DATA";
export const SERVICES_DATA = "SERVICES_DATA";
export const MEMBERSHIPS_DATA = "MEMBERSHIPS_DATA";
export const CATEGORY_DATA = "CATEGORY_DATA";
export const PRODUCTS_DATA = "PRODUCTS_DATA";
export const CUSTOMER_DATA = "CUSTOMER_DATA";
export const ADD_CUSTOMER = "ADD_CUSTOMER";
export const PROMOTIONS_DATA = "PROMOTIONS_DATA";
export const HOLD_ORDER = "HOLD_ORDER";
export const REMOVE_HOLD_ORDER = "REMOVE_HOLD_ORDER";
export const RESET_CART = "RESET_CART";
export const GET_BILLING_PRODUCT_MODIFIERS = "GET_BILLING_PRODUCT_MODIFIERS";
export const ADD_HOLD_ORDER_TO_CART = "ADD_HOLD_ORDER_TO_CART";
export const CHARGE_TAX_DATA = "CHARGE_TAX_DATA";
export const UNIT_DATA = "UNIT_DATA";
export const PRODUCT_GROUP_DATA = "PRODUCT_GROUP_DATA";
export const MODIFIER_GROUPS_DATA = "MODIFIER_GROUPS_DATA";
export const STORE_PAYMENT_DATA = "STORE_PAYMENT_DATA";
export const GET_PRODUCT_MODIFIERS = "GET_PRODUCT_MODIFIERS"
export const APP_SETTINGS = "APP_SETTINGS";
export const CREATE_PRINTER_REQUEST='CREATE_PRINTER_REQUEST';
export const CREATE_PRINTER_SUCCESS='CREATE_PRINTER_SUCCESS';
export const CREATE_PRINTER_FAILURE='CREATE_PRINTER_FAILURE';
export const UPDATE_CUSTOMER='UPDATE_CUSTOMER';
export const BILLING_DEVICE_INFO='BILLING_DEVICE_INFO';
export const BILLING_TOGGLE_SHORTCUTS='BILLING_TOGGLE_SHORTCUTS';

//urbanpiper
export const FETCH_UP_CHAIN_SETTINGS_REQUEST= "FETCH_UP_CHAIN_SETTINGS_REQUEST"
export const FETCH_UP_CHAIN_SETTINGS_SUCCESS= "FETCH_UP_CHAIN_SETTINGS_SUCCESS"
export const FETCH_UP_CHAIN_SETTINGS_FAILURE= "FETCH_UP_CHAIN_SETTINGS_FAILURE"
export const FETCH_UP_STORE_SETTINGS_REQUEST= "FETCH_UP_STORE_SETTINGS_REQUEST"
export const FETCH_UP_STORE_SETTINGS_SUCCESS= "FETCH_UP_STORE_SETTINGS_SUCCESS"
export const FETCH_UP_STORE_SETTINGS_FAILURE= "FETCH_UP_STORE_SETTINGS_FAILURE"

export const FETCH_URBANPIPER_STOREPRODS_REQUEST= "FETCH_URBANPIPER_STOREPRODS_REQUEST"
export const FETCH_URBANPIPER_STOREPRODS_SUCCESS= "FETCH_URBANPIPER_STOREPRODS_SUCCESS"
export const FETCH_URBANPIPER_STOREPRODS_FAILURE= "FETCH_URBANPIPER_STOREPRODS_FAILURE"

export const EDIT_URBANPIPER_SINGLEPROD= "EDIT_URBANPIPER_SINGLEPROD"
export const EDIT_URBANPIPER_MULTIPROD= "EDIT_URBANPIPER_MULTIPROD"
export const VERIFY_URBANPIPER_MENU= "VERIFY_URBANPIPER_MENU"
export const VERIFY_AGGREGATOR_MENU= "VERIFY_AGGREGATOR_MENU"

export const UPBANPIPER_API_LOGS_REQUEST = "UPBANPIPER_API_LOGS_REQUEST"
export const UPBANPIPER_API_LOGS_SUCCESS = "UPBANPIPER_API_LOGS_SUCCESS"
export const UPBANPIPER_API_LOGS_FAILURE = "UPBANPIPER_API_LOGS_FAILURE"

export const PUBLISH_UP_BULK_MENU = "PUBLISH_UP_BULK_MENU"
export const PUSH_UP_STORE_CATALOGUE = "PUSH_UP_STORE_CATALOGUE"
export const PUSH_AG_STORE_CATALOGUE = "PUSH_AG_STORE_CATALOGUE"
export const LAST_UP_MENUSYNC_JSON = "LAST_UP_MENUSYNC_JSON"

export const LAST_UP_MENUSYNC='LAST_UP_MENUSYNC'

export const FETCH_BUNDLE_LIST_REQUEST='FETCH_BUNDLE_LIST_REQUEST'
export const FETCH_BUNDLE_LIST_SUCCESS='FETCH_BUNDLE_LIST_SUCCESS'
export const FETCH_BUNDLE_LIST_FAILURE='FETCH_BUNDLE_LIST_FAILURE'
export const FETCH_BUNDLE_SALES_SUCCESS="FETCH_BUNDLE_SALES_SUCCESS"


export const FETCH_SALES_TARGET_PERIOD_REQUEST= "FETCH_SALES_TARGET_PERIOD_REQUEST"
export const FETCH_SALES_TARGET_PERIOD_SUCCESS= "FETCH_SALES_TARGET_PERIOD_SUCCESS"
export const  FETCH_SALES_TARGET_PERIOD_FAILURE= "FETCH_SALES_TARGET_PERIOD_FAILURE"

export const FETCH_SALES_TARGET_CONFIGS_REQUEST= "FETCH_SALES_TARGET_CONFIGS_REQUEST"
export const FETCH_SALES_TARGET_CONFIGS_SUCCESS= "FETCH_SALES_TARGET_CONFIGS_SUCCESS"
export const FETCH_SALES_TARGET_CONFIGS_FAILURE= "FETCH_SALES_TARGET_CONFIGS_FAILURE"
export const FETCH_COUNTER_WISE_SALES_REQUEST='FETCH_COUNTER_WISE_SALES_REQUEST'
export const FETCH_COUNTER_WISE_SALES_SUCCESS='FETCH_COUNTER_WISE_SALES_SUCCESS'
export const FETCH_COUNTER_WISE_SALES_FAILURE='FETCH_COUNTER_WISE_SALES_FAILURE'

export const CHAIN_LEVEL_STORES = 'CHAIN_LEVEL_STORES'
export const FETCH_SOURCE_USER_REQUEST= 'FETCH_SOURCE_USER_REQUEST'
export const FETCH_SOURCE_USER_SUCCESS= 'FETCH_SOURCE_USER_SUCCESS'
export const FETCH_SOURCE_USER_FAILURE= 'FETCH_SOURCE_USER_FAILURE'

export const ADD_GIFTVOUCHER='ADD_GIFTVOUCHER'
export const EDIT_GIFTVOUCHER='EDIT_GIFTVOUCHER'
export const GET_GIFTVOUCHER_REQUEST='GET_GIFTVOUCHER_REQUEST'
export const GET_GIFTVOUCHER_SUCCESS='GET_GIFTVOUCHER_SUCCESS'
export const GET_GIFTVOUCHER_FAILURE='GET_GIFTVOUCHER_FAILURE'

export const GET_LIST_OF_PRICELIST_REQUEST = 'GET_LIST_OF_PRICELIST_REQUEST'
export const GET_LIST_OF_PRICELIST_SUCCESS = 'GET_LIST_OF_PRICELIST_SUCCESS'
export const GET_LIST_OF_PRICELIST_FAILURE = 'GET_LIST_OF_PRICELIST_FAILURE'

export const GET_ALL_CHAIN_CUSTOMERS_REQUEST = 'GET_ALL_CHAIN_CUSTOMERS_REQUEST'
export const GET_ALL_CHAIN_CUSTOMERS_SUCCESS = 'GET_ALL_CHAIN_CUSTOMERS_SUCCESS'
export const GET_ALL_CHAIN_CUSTOMERS_FAILURE = 'GET_ALL_CHAIN_CUSTOMERS_FAILURE'

export const GET_ALL_STORE_PRODUCTS_REQUEST = 'GET_ALL_STORE_PRODUCTS_REQUEST'
export const GET_ALL_STORE_PRODUCTS_SUCCESS = 'GET_ALL_STORE_PRODUCTS_SUCCESS'
export const GET_ALL_STORE_PRODUCTS_FAILURE = 'GET_ALL_STORE_PRODUCTS_FAILURE'
export const SET_HISTORY = "SET_HISTORY";

export const GET_ALL_HSNCODE_REQUEST = 'GET_ALL_STORE_PRODUCTS_REQUEST'
export const GET_ALL_HSNCODE_SUCCESS = 'GET_ALL_STORE_PRODUCTS_SUCCESS'
export const GET_ALL_HSNCODE_FAILURE = 'GET_ALL_STORE_PRODUCTS_FAILURE'

export const GET_ALL_GROUP_FILTER_REQUEST = 'GET_ALL_GROUP_FILTER_REQUEST'
export const GET_ALL_GROUP_FILTER_SUCCESS = 'GET_ALL_GROUP_FILTER_SUCCESS'
export const GET_ALL_GROUP_FILTER_FAILURE = 'GET_ALL_GROUP_FILTER_FAILURE'

export const GET_ALL_FILTER_REQUEST = 'GET_ALL_FILTER_REQUEST'
export const GET_ALL_FILTER_SUCCESS = 'GET_ALL_FILTER_SUCCESS'
export const GET_ALL_FILTER_FAILURE = 'GET_ALL_FILTER_FAILURE'

export const FETCH_ENTITY_SALES_REQUEST = 'FETCH_ENTITY_SALES_REQUEST'
export const FETCH_ENTITY_SALES_SUCCESS = 'FETCH_ENTITY_SALES_SUCCESS'
export const FETCH_ENTITY_SALES_FAILURE = 'FETCH_ENTITY_SALES_FAILURE'

